@import '../../../../stylesheets/variables.scss';

.DetailsContentLoader__mobile {
  @media (min-width: $lg) {
    display: none;
  }
}

.DetailsContentLoader__desktop {
  display: none;
  @media (min-width: $lg) {
    display: block;
    margin-left: 424px;
    width: calc(100vw - 424px);
  }
}
