@import '../../../stylesheets/variables.scss';

.AddCustomPriceDialogRoot {
  z-index: 1500 !important;
}

.AddCustomPriceDialogPaper {
  margin: 20px !important;
  min-width: 90vw;
  max-width: 90vw;
  border: 2px solid $fbGrey__lightest;
  border-radius: $borderRadius__modal;
  min-height: 600px;

  @media (min-width: $md) {
    min-width: 650px;
    max-width: 650px;
  }
}

.AddCustomPriceDialog__header {
  background: white;
  border-radius: $borderRadius__modal $borderRadius__modal 0 0;
  color: $fbGrey__dark;
}

.AddCustomPriceDialog__closeBtn {
  position: absolute;
  right: 0;
  top: 0;
}

.CustomPricingForm {
  margin-top: 40px;
}

.CustomPricingForm__actions {
  margin-top: 40px;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
}

.Link {
  text-decoration: underline;
  color: $fbGrey__dark;

  &:hover {
    color: $fbGrey__standard;
  }
}

.APIErrorContainer {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.APIError {
  width: 400px;
  max-width: 90%;
}

.APIError__content {
  margin-top: 12px;
}

.OrangeLink {
  color: $fbOrange__standard;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    color: $fbOrange__dark;
    text-decoration: underline;
  }
}

.ErrorMessageContainer {
  min-height: 24px;
  padding: 4px 8px;
  font: $font__bodyRegular;
  font-size: 12px;
  line-height: 140%;
  color: $fbRed__standard !important;
}

.SecondaryText {
  font: $font__bodyRegular;
  font-size: 14px;
  line-height: 140%;
  color: $fbGrey__standard;

  &.error {
    color: $fbRed__standard !important;
  }
}

.InputRow {
  flex-wrap: wrap;
  width: 100%;
  display: flex;

  @media (min-width: $md) {
    flex-wrap: nowrap;
  }
}

.FullWidthInputContainer {
  width: 100%;
}

.SubmitBtn {
  margin: 0;
}

.NotificationSpaceBlocker {
  padding-top: 44px;
}

.InputField {
  padding: 12px 16px;
  border-radius: $borderRadius__button;
  background: white !important;
  font: $font__bodyRegular;
  color: $fbGrey__dark;
  font-size: 16px;
  line-height: 140%;
  box-shadow: $lightBoxShadow;
  width: 100%;
  outline: none;
  border: none;
  position: relative;
  box-sizing: border-box;

  &::placeholder {
    color: $fbGrey__light !important;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.focused,
  &:focus {
    outline: 1px auto $fbGreen__light;
  }
}

strong {
  font-weight: bold !important;
}

.ErrorContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.CustomPriceExistsError {
  padding-top: 16px;
}

.CustomPriceExistsError__body {
  padding-bottom: 16px;
}

.SubmitLoadingSpinner {
  line-height: 24px;
  width: 22px !important;
  height: 22px !important;
  color: $fbGrey__light;
}
