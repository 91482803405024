@import '../../../../stylesheets/variables.scss';

.DeliveryDays__day {
  font: $font__bodyBold;
  display: inline-block;
  margin-right: 5px;

  &.Day__active {
    color: $fbGreen__standard;

    &.Greyscale {
      color: $fbGrey__standard;
    }
  }

  &.Day__inactive {
    color: $fbGrey__light;
  }
}
