@import '../../../stylesheets/variables.scss';

.BySuburbs__container {
  width: 100%;
  overflow-y: auto;

  @media (min-width: $lg) {
    margin-left: 424px;
    width: calc(100vw - 424px);
  }
}

.BySuburbs__headerContainer {
  background: white;
  box-shadow: $lightBoxShadow;
}

.BySuburbs__header {
  padding: 16px;
  @media (min-width: $sm) {
    padding: 32px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: $lg) {
    padding: 42px;
  }
}

.HeaderActionsContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.ActionButton__icon {
  margin-right: 8px;
}

.FilterMenuBtn {
  margin-left: 0;
  margin-bottom: 0;
  @media (min-width: $md) {
    margin: 12px;
  }
  @media (min-width: $lg) {
    margin: 12px;
  }
}

.MobileMenuBtn {
  margin-left: 0;
  @media (min-width: $lg) {
    margin-left: 12px;
  }
}

.MenuLink {
  font: $font__bodyRegular;
  font-size: 18px;
  color: $fbGrey__standard;
  border-radius: $borderRadius__button;

  &:focus {
    color: $fbGrey__standard;
    text-decoration: none;
    outline: none;
  }

  &:hover,
  &:active {
    text-decoration: none;
  }
}

.SuburbSearchForm {
  min-width: 280px;
  width: 100%;
  @media (min-width: $md) {
    width: auto;
    flex: 1;
    max-width: 450px;
  }
}

.NoResultsContainer {
  text-align: center;
  margin: auto;
  margin: 16px;
  padding: 12px;

  @media (min-width: $sm) {
    margin: 22px 12px 22px 12px;
    padding: 25px;
  }
}

.RemoveFilterBtn {
  margin: auto;
}

.TypographySection {
  padding-top: 12px;
  padding-bottom: 12px;
}

.TypographyBody {
  margin-bottom: 8px;
}

.TableContainer {
  margin-top: 40px;
  margin-bottom: 120px;
}

.ZoneSuburbsTable {
  td,
  th {
    width: 150px;
    padding-right: 4px;
    padding-left: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.Table__status {
      width: 55px;
      min-width: 55px;
    }

    &.Table__postcode {
      width: 80px;
    }

    &.Table__suburb {
      width: 150px;
    }

    &.Table__zoneName {
      width: 200px;
    }

    &.Table__published {
      width: 110px;
    }

    &.Table__days {
      width: 130px;
    }

    &.Table__minimumOrderTotal {
      width: 75px;
    }

    &.Table__cutoff {
      width: 75px;
    }
  }

  th {
    &:first-child {
      z-index: 0;
    }
  }
}

.ZoneSuburbStatusChip {
  margin-left: 0;
}

.ZoneStatusChip {
  margin: 0;
}

.RowActionBtn {
  margin: 0;
}
