@import '../../stylesheets/variables.scss';

.Dialog {
  background: $fbGrey__lightest;
}

.DialogTitle {
  background: $fbGrey__lightest;
}

.ImageSelectorContainer {
  display: flex;
  width: 470px;
  height: 488px;
  background: $fbGrey__lightest;
  margin: 12;
  padding-top: 0;
}

.ImageSelectorHeader {
  padding-bottom: 24px;
  background: $fbGrey__lightest;
}

.CloseBtn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 11;
}

.HeaderContent {
  display: inline-block;
  flex-direction: column;
  position: fixed;
  z-index: 10;
  width: inherit;
  background: $fbGrey__lightest;
  padding-bottom: 12px;
  border-bottom: 1px;
  border-bottom-style: inset;
}

.ProductInfoSection {
  display: flex;
  flex-direction: column;
}

.ProductInfo {
  padding-bottom: 12px;
}

.SearchBarContainer {
  margin-top: 24px;
}

.ImageSection {
  width: 100%;
  margin-top: 216px;
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  overflow-x: hidden;
  overflow-x: auto;
  text-align: justify;

  &.newProduct {
    margin-top: 150px;
  }
}

.ProductImageRow {
  display: flex;
  width: 100%;
  justify-content: left;
  padding-right: 12px;
  margin-left: 0;
  margin-right: 0;

  &:focus {
    outline: 5px auto $fbGreen__light;
    background: $fbGreen__lightest;
  }

  &:hover {
    background: $fbGreen__lightest !important;
  }

  &.selected {
    outline: 5px auto $fbGreen__light;
    background: $fbGreen__lightest;
  }
}

.ProductImg {
  width: 36px;
  height: 36px;
  align-self: start;
  padding-right: 18px;
}

.DialogFooter {
  display: flex;
  background: $fbGrey__lightest;
}

.ProgressSpinner {
  color: white;
  line-height: 18px;
}

.ProgressText {
  color: white;
}

.SaveError {
  min-width: 100%;
  margin: 0;
}

.FooterActions {
  display: flex;
  margin-left: 24px;
  margin-right: 48px;
  background: $fbGrey__lightest;
}

.PrefilBtnContainer {
  display: flex;
}

.PrefilBtn {
  margin: 8px 8px 0 0;
}
