@import '../../../stylesheets/variables.scss';

.HeaderContainer {
  background: white;
}

.HeaderContent {
  padding: 20px 12px;
  max-width: $maxPageWidth;
  margin: auto;

  @media (min-width: $md) {
    padding: 20px 32px;
  }
}

.SpecialContentContainer {
  max-width: $maxPageWidth;
  margin: auto;
  padding-top: 12px;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: $md) {
    padding-top: 24px;
    flex-wrap: nowrap;
  }
}

.ProductsErrorContainer {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.SpecialFormContainer {
  width: 100%;
  margin-right: 12px;
  margin-bottom: 24px;
  margin-left: 12px;

  @media (min-width: $md) {
    margin-left: 0;
    margin-bottom: 0;
    width: 65%;
  }
}

.DiscountTypesButton {
  min-width: 440px;
}

.SpecialSummary {
  width: 100%;
  margin-left: 12px;
  margin-right: 12px;
  overflow: auto;

  @media (min-width: $md) {
    margin-right: 0;
    width: 30%;
  }
}

.SpecialSummary__header {
  text-align: center;
}

.BlockHeading {
  margin-bottom: 16px;
}

.Bold {
  font-weight: bold;
}

.FakeLineBreak {
  padding: 12px;
}

.SpecialForm {
  margin-top: 16px;
}

.InputRow {
  flex-wrap: wrap;
  width: 100%;
  display: flex;

  @media (min-width: $md) {
    flex-wrap: nowrap;
  }
}

.Input__halfWidth {
  width: 100%;

  @media (min-width: $md) {
    flex: 1;

    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }
}

.Input__fullWidth {
  width: 100%;
}

.DateInputsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BetweenDatesIcon {
  margin-top: 20px;
  color: $fbGrey__light;
}

.DateInput {
  width: 100%;

  &:first-child {
    margin-right: 6px;
  }

  &:last-child {
    margin-left: 6px;
  }
}

.ProductSelectInputContainer {
  width: 100%;
}

.SubmitBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.SubmitBtn__loading {
  color: white;

  svg {
    margin: 0 !important;
  }
}

.ErrorMessageContainer {
  min-height: 24px;
  padding: 4px 8px;
  font: $font__bodyRegular;
  font-size: 12px;
  line-height: 140%;
  color: $fbRed__standard !important;
}

.InputLabel {
  margin-left: 8px;
  font: $font__bodyRegular;
  font-size: 14px;
  line-height: 140%;
  color: $fbGrey__standard;

  &.error {
    color: $fbRed__standard !important;
  }
}

.InputField {
  padding: 12px 16px;
  border-radius: $borderRadius__button;
  background: white !important;
  font: $font__bodyRegular;
  color: $fbGrey__dark;
  font-size: 16px;
  line-height: 140%;
  border: 1px solid $fbGrey__standard;
  box-shadow: $lightBoxShadow;
  width: 100%;
  outline: none;
  position: relative;
  box-sizing: border-box;

  &::placeholder {
    color: $fbGrey__light !important;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.focused,
  &:focus {
    outline: 1px auto $fbGreen__light;
  }
}

.OrangeLink {
  color: $fbOrange__standard;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    color: $fbOrange__dark;
    text-decoration: underline;
  }
}

.FeatureSpecialCheckboxContainer {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.CheckboxLabel {
  font: $font__bodyRegular;
  font-size: 16.5px;
  line-height: 140%;
  color: $fbGrey__dark;
}

.GreenSecondaryText {
  font: $font__bodyRegular;
  font-size: 16.5px;
  line-height: 140%;
  color: $fbGreen__standard;
}

.SignificantPriceWarning {
  text-align: center;
  padding-top: 20px;
  font: $font__bodyBold;
  font-size: 16.5px;
  line-height: 140%;
  color: $fbRed__standard;
}

.GSTWarning {
  text-align: center;
  padding-bottom: 20px;
  font: $font__bodyBold;
  font-size: 16.5px;
  line-height: 140%;
  color: $fbOrange__standard;
}
