@import '../../../stylesheets/variables.scss';

.LargeLoadingSpinnerContainer {
  text-align: center;
  margin-top: 100px;

  &.small {
    margin-top: 15px;
  }
}

.CircularProgress {
  margin-top: 60px;
  font-size: 22px;

  &.small {
    margin-top: 15px;
    font-size: 14px;
  }
}

.LoadingText {
  margin-top: 30px;

  &.small {
    margin-top: 15px;
  }
}

.Circle {
  color: $fbGreen__standard;
}
