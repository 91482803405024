@import '../../stylesheets/variables.scss';

.DrawToggle {
  transition: all $navigationTransitionTime;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 99;
  width: 19px;

  &.open {
    .spinner1 {
      transform: rotate(135deg);
      margin-top: 6.4px;
    }

    .spinner2 {
      opacity: 0;
    }

    .spinner3 {
      transform: rotate(-135deg);
      margin-top: -7.2px;
    }
  }
}

.DrawToggle__spinner {
  height: 2px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  background-color: $fbGrey__light;
  transition: all $navigationTransitionTime;
  float: left;

  &.spinner1 {
    margin-top: 0;
  }

  &.spinner2 {
    margin-top: 3px;
  }

  &.spinner3 {
    margin-top: 3px;
  }
}
