@import '../../stylesheets/variables.scss';

.LossLeaderProductsContainer {
  width: 100%;
  min-height: 300px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  padding-top: 20px;

  &.LossLeaderProducts {
    min-height: 290px;
  }

  @media (min-width: $md) {
    margin-bottom: 16px;
    max-width: 590px;
  }
}

.LossLeaderProducts__title {
  margin-bottom: 10px;
}
.LossLeaderProducts__subTitle {
  margin-bottom: 20px;
  color: $fbGrey__standard;
}

.LossLeaderProducts__bodyInfo {
  margin: 15px auto;
  text-align: center;
  max-width: 460px;
}
.LossLeaderProducts__bodyText {
  margin-top: 14px;
  line-height: 1.8;
  margin-bottom: 18px;
  color: $fbGrey__standard;
}

.LossLeaderProductsAction {
  margin-top: 40px;
}

.ActionIcon {
  margin-right: 10px;
}

.LossProductsInfoContainer {
  margin: 10px 2px;
  max-width: 530px;
}

.LossProductInfoHeaderRow {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid $fbGrey__lightest;
  margin-bottom: 5px;
}

.LossProductInfo__bodyRow {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $fbGrey__lightest;
  transition: all $navigationTransitionTime;
  padding: 1px 1px 4px 1px;

  &:hover {
    background-color: $fbGrey__lightest;
  }
}

.ProductName__head {
  flex-grow: 0.85;
}

.ProductName__body {
  text-decoration: none;
  flex-grow: 1.5;
  width: 200px;
  text-align: start;
}

.ProductName__text {
  color: $fbGrey__standard;
}

.ProductSKU__body {
  color: $fbGrey__standard;
  flex-grow: 0.35;
  width: 70px;
}

.ProductSKU__head {
  flex-grow: 0.22;
}

.LossProductsDeleteButton {
  background: transparent;
  border: none;
  margin-top: 4px;
  cursor: pointer;
  transition: all $navigationTransitionTime;
  border-radius: 50%;
  width: 40px;
  height: 40px;

  &:hover {
    background-color: rgb(221, 221, 221);
  }
}

.LossProductsIcon {
  color: $fbRed__standard;
}

.FakeBtn {
  border: none;
  color: none;
  background: transparent;
  cursor: pointer;
}

.ErrorWrapper {
  margin: auto;
  max-width: 94%;
  max-width: 400px;
}

.LossLeaderProductAddButton {
  &:disabled {
    background: $fbGrey__lightest;
    border: 0.8px solid $fbGrey__light;
    color: $fbGrey__standard;

    &:hover {
      background: $fbGrey__lightest;
      border: 0.8px solid $fbGrey__light;
      box-shadow: none;
      color: $fbGrey__standard;

      svg {
        color: $fbGrey__standard;
      }
    }
  }
}
