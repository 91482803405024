@import '../../stylesheets/variables.scss';

.SettingsContainer {
  padding-bottom: 80px;
  overflow: hidden;

  @media (min-width: $md) {
    overflow: unset;
  }
}

.PageContainer {
  max-width: $maxPageWidth;
  margin: auto;
}

.SettingsCard {
  position: relative;
  margin-bottom: 16px;

  @media (min-width: $md) {
    margin-bottom: 28px;
  }

  &.pulse {
    transform: scale(1);
    animation: pulse 0.8s infinite;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }

  70% {
    transform: scale(0.98);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.SettingsCard__completeIcon {
  transition: all $navigationTransitionTime;
  color: $fbGreen__light;
  position: absolute;
  top: 24px;
  right: 16px;
  width: 24px;
  height: 24px;

  &.hide {
    right: 32px;
    top: 32px;
    width: 0;
    height: 0;
  }
}

.SettingsCard__invalidIcon {
  transition: all $navigationTransitionTime;
  color: $fbRed__standard;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;

  &.hide {
    right: 32px;
    top: 32px;
    width: 0;
    height: 0;
  }
}

.SettingsCard__expandableIcon {
  transition: all $navigationTransitionTime;
  color: $fbGrey__light;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;

  &.hide {
    right: 32px;
    top: 32px;
    width: 0;
    height: 0;
  }
}

.SettingsCard__sectionHeading {
  margin-bottom: 16px;

  .RequiredIcon {
    color: $fbRed__light;
    cursor: pointer;
  }
}

.TooltipIcon {
  cursor: pointer;
}

.SettingsCard__description {
  margin-bottom: 28px;
  color: $fbGrey__standard;
}

.SettingsCard__summary_details {
  border-bottom: 1px $fbGrey__light solid;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.SettingsCard__summary_details_container {
  &:last-child {
    border-bottom: none;
  }
}

.SettingsCard__summary_details:last-child {
  border-bottom: none;
}

.SettingsCard__summary {
  color: $fbGrey__standard;

  &.title {
    padding-bottom: 12px;
  }
}
.SettingsCard__addBtnContainer {
  width: 100%;
  margin: 20px 0 0 0;

  button {
    width: 100%;
    margin: 10px auto;
  }
}

.SettingsCard__editBtnContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.EditBtn {
  margin: 16px 0 0 0;
}

.SubmitLoadingSpinner {
  line-height: 24px;
  width: 22px !important;
  height: 22px !important;
  color: $fbGrey__light;

  svg {
    color: $fbGrey__dark !important;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.ErrorMessageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Settings {
  display: flex;
  width: 100%;
}

.ErrorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Settings__links {
  position: sticky;
  top: 74px;
  height: 400px;

  &.ForStaff {
    height: 900px;
  }

  flex: 1;
  padding-top: 40px;
  display: none;
  padding-left: 8px;
  padding-right: 8px;

  @media (min-width: $md) {
    display: block;
  }
}

.Settings__cards {
  flex: 1;
  padding-top: 16px;
  padding-left: 8px;
  padding-right: 8px;

  @media (min-width: $md) {
    padding-top: 40px;
  }
}

.SettingsLink {
  width: 100%;
}

.SettingsLinkBtn {
  transition: all $navigationTransitionTime;
  text-align: left;
  cursor: pointer;
  outline: none;
  background: none;
  margin: 0 0 8px 0;
  border: none;
  font: $font__headingBold;
  font-size: 22px;
  color: $fbGrey__dark;
  line-height: 140%;
  display: flex;
  align-items: center;

  @media (min-width: $md) {
    font-size: 26px;
    line-height: 140%;
  }

  @media (min-width: $lg) {
    font-size: 26px;
    line-height: 140%;
  }

  &:hover {
    color: $fbGrey__standard;
  }

  &.error {
    color: $fbRed__standard;

    &:hover {
      color: $fbRed__light;
    }
  }
}

.SettingsLinkBtn__errorIcon {
  transition: all $navigationTransitionTime;
  width: 24px;
  height: 24px;

  &.hide {
    width: 0;
    height: 0;
  }
}

.RedText {
  color: $fbRed__standard;
}

.FormRow {
  display: flex;
}

.BSBFormInput {
  width: 38%;
  margin-right: 8px;
}

.AccountNumberFormInput {
  width: 62%;
  margin-left: 8px;
}

.RegionLineContainer {
  margin-bottom: 20px;
}

.RegionLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.RegionLine__left {
  display: flex;
  align-items: center;
}

.RegionLine__title {
  margin: 0;
}

.RegionLine__details {
  display: flex;
  flex-wrap: wrap;
}

.RegionWeekday {
  color: $fbGrey__light;
  padding-left: 4px;

  &.enabled {
    color: $fbGreen__standard;
  }
}

.RegionLineDetailBlock {
  width: 44%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;

  &:nth-child(odd) {
    padding-right: 24px;
  }

  &:nth-child(even) {
    padding-left: 24px;
  }
}

.Switch__root {
  width: 36px;
  height: 18px;
  display: flex;
  align-items: center;
  padding: 2px;
  margin-left: 8px;
  &.disabled {
    cursor: not-allowed;
    filter: grayscale(0.5);
  }

  &:hover {
    background: $fbGrey__lightest;
    border-radius: 16px;
  }
}

.Switch__switchBase {
  padding: 0;
  margin-left: 5px;
  margin-top: 3px;
  color: $fbGrey__light;

  &.checked {
    transform: translateX(14px);
    color: $fbGreen__standard;
  }
}

.Switch__thumb {
  width: 12px;
  height: 12px;
  box-shadow: none;
}

.Switch__track {
  border-radius: 10px;
  opacity: 1;
  background: transparent;
  border: 2px solid $fbGrey__light;

  &.checked {
    opacity: 1 !important;
    background: transparent !important;
    border: 2px solid $fbGreen__standard;
  }
}

.EditRegionIcon {
  color: $fbGrey__light;
}

.CenterButton {
  margin-left: auto;
  margin-right: auto;
}

.SubstitutionHeader__action,
.b2cEnabledHeader__action {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

.ToggleButton {
  display: flex;
  align-items: center;
}

.ToggleInfo {
  max-width: 300px;
}

.ToggleSubText {
  color: $fbGrey__standard;
  margin-top: 10px;
}

.ToggleContainer {
  width: 40px;
  margin: 0.1px 5px;
  border-radius: 100px;
  height: 22px;
  padding: 2px 4px;
  background: $fbGreen__standard;
  display: flex;
  align-items: center;
  transition: all $navigationTransitionTime;

  &.ToggleOff {
    background: $fbGrey__light;
  }

  .ToggleCircle {
    width: 18px;
    height: 20px;
    background-color: $fbGrey__white;
    border-radius: 50%;
    color: transparent;
    border: none;
    cursor: pointer;
    transition: all $navigationTransitionTime;

    &.ToggleOn {
      margin-left: 20px;
    }
  }
}

.ToggleFooterActionContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;

  button {
    margin-bottom: 4px;
  }
}

.ColorPrimary {
  color: white;
  margin-top: 4px;
  &:hover {
    background: none;
  }
}

.ActiveColor {
  background-color: $fbGreen__light !important;
  border-radius: 90px;
}

.PausedColor {
  background-color: $fbGrey__light !important;
  border-radius: 90px;
}

.SwitchRoot {
  height: 47px;
  width: 60px;
}

// image uploader
.SettingsCard__imageUploader__subHeading {
  max-width: 340px;
}

.ImageUpload__container {
  margin: 20px 0;
}

.Divider {
  width: 100%;
  height: 1px;
  background-color: $fbGrey__light;
  margin: 20px auto;
}

.ImageUpload__errorMessage {
  color: $fbRed__light;
}

.NewCustomers__buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;

  .buttonExplanationText {
    margin-bottom: 0;
  }

  .lightColor {
    color: $fbGreen__light;
    &:hover {
      background: none;
    }
  }

  .greyedOut {
    color: $fbGrey__light;
    &:hover {
      background: none;
    }
  }

  .Toggled {
    background-color: $fbGreen__light !important;
    border-radius: 90px;
  }

  .Untoggled {
    background-color: $fbGrey__light !important;
    border-radius: 90px;
  }
}
