@import '../../stylesheets/variables.scss';

.LossLeaderVenuesContainer {
  width: 100%;
  min-height: 300px;
  padding-bottom: 10px;
  padding-top: 20px;

  &.LossVenues {
    min-height: 255px;
  }

  @media (min-width: $md) {
    max-width: 440px;
    margin-bottom: 16px;
  }
}

.LossLeaderVenuesTitle {
  margin-bottom: 30px;
}

.LossLeaderVenuesBodyInfo {
  margin: 15px auto;
  text-align: center;
  max-width: 460px;
  min-height: 82px;
  align-self: center;
}

.LossLeaderVenuesBodyText {
  margin-top: 50px;
  line-height: 1.8;
  margin-bottom: 18px;
  color: $fbGrey__standard;
}

.LossLeaderVenuesAction {
  margin-top: 41px;
}

.ActionIcon {
  margin-right: 10px;
}

.LossVenuesBodyInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1px 6px 4px 6px;
  border-bottom: 1px solid $fbGrey__lightest;
  transition: all $navigationTransitionTime;

  &:hover {
    background-color: $fbGrey__lightest;
  }
}

.LossVenuesDeleteButton {
  background: transparent;
  border: none;
  margin-top: 2px;
  cursor: pointer;
  transition: all $navigationTransitionTime;
  border-radius: 50%;
  width: 40px;
  height: 40px;

  &:hover {
    background-color: rgb(221, 221, 221);
  }
}

.LossVenuesIcon {
  color: $fbRed__standard;
}

.FakeBtn {
  border: none;
  color: none;
  background: transparent;
  cursor: pointer;
}

.ErrorWrapper {
  margin: auto;
  max-width: 94%;
  max-width: 350px;
}

.LossLeaderVenuesAddButton {
  &:disabled {
    background: $fbGrey__lightest;
    border: 0.8px solid $fbGrey__light;
    color: $fbGrey__standard;

    &:hover {
      background: $fbGrey__lightest;
      border: 0.8px solid $fbGrey__light;
      box-shadow: none;
      color: $fbGrey__standard;

      svg {
        color: $fbGrey__standard;
      }
    }
  }
}
