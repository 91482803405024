@import '../../stylesheets/variables.scss';

.CustomPrice {
  max-width: 100%;
}

.CustomPriceForm {
  margin-left: 8px;
  margin-right: 8px;
}

.CustomPriceForm__row {
  display: flex;
  flex-wrap: wrap;
  transition: all $navigationTransitionTime;

  @media (min-width: $lg) {
    flex-wrap: nowrap;
  }

  &.deleted {
    display: none;
  }
}

.CustomPriceForm__addBtnContainer {
  margin-top: 12px;
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.CustomPriceForm__submitBtnContainer {
  border-top: 1px solid $fbGrey__light;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 10px;
  margin-top: 16px;
  margin-bottom: 12px;
}

.CustomPriceForm__fields {
  max-height: 340px;
  overflow-y: scroll;

  @media (min-width: $md) {
    max-height: 400px;
  }
}

.NoMarginBtn {
  margin: 0;
}

.InputContainer {
  &.InputContainer__customer {
    width: 100%;

    @media (min-width: $md) {
      width: auto;
      flex-grow: 1;
      margin-right: 12px;
    }
  }

  &.InputContainer__price {
    width: 100%;

    @media (min-width: $md) {
      width: 200px;
      margin-left: 12px;
    }
  }
}

.DeleteCustomPriceBtn {
  min-width: unset;
  margin-top: 18px;
  padding: 14px 10px;

  .Icon {
    color: $fbRed__standard !important;
  }

  &:hover {
    .Icon {
      color: white !important;
    }
  }

  &:disabled {
    .Icon {
      color: $fbGrey__standard;
    }
  }
}

.ProductTitle {
  padding-bottom: 16px;
}

.EditAllPricesSection {
  display: flex;
}

.ApplyToAllBtn {
  margin-top: 20px;
  margin-left: 12px;
  margin-right: 0;
  height: 48px;
  @media (min-width: $md) {
    margin-right: 70px;
    width: 200px;
  }
}
