@import '../../stylesheets/variables';

.ConfirmationDeleteDialogRoot {
  z-index: 1500 !important;
}

.ConfirmationDeleteDialogPaper {
  margin: 20px !important;
  min-width: 90vw;
  max-width: 90vw;
  border: 2px solid $fbGrey__lightest;
  border-radius: $borderRadius__modal;
  min-height: 300px;

  @media (min-width: $md) {
    min-width: 450px;
    max-width: 450px;
  }
}

.ConfirmationDeleteDialog__header {
  background: white;
  border-radius: $borderRadius__modal $borderRadius__modal 0 0;
  color: $fbGrey__dark;
  padding: 60px 0 16px 0;

  h2 {
    text-align: center;
  }
}

.ConfirmationDeleteDialog__closeBtn {
  position: absolute;
  right: 0;
  top: 0;
}

.ConfirmationDeleteDialogContent {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  div {
    margin: 10px 0;
    text-align: center;
  }
}

.ConfirmationDeleteDialogFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  padding-top: 1px;

  button {
    width: 40%;
  }
}
