@import '../../../stylesheets/variables.scss';

.ImageUpload__wrapper {
  margin: 10px 0;
}

.UploadSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: $borderRadius__modal;
  position: relative;
  box-shadow: $lightBoxShadow;
  cursor: pointer;
  margin: 10px auto 30px;

  @media (min-width: $md) {
    box-shadow: $elevatedShadow;
    height: 160px;
  }
}

.InputField {
  opacity: 0;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &:disabled {
    cursor: not-allowed;
  }
}

.UploadIcon__container {
  background-color: #fbfbfb;
  border-radius: 50%;
  padding: 10px;
  margin-bottom: 16px;
}

.UploadIcon {
  color: $fbGrey__standard;
  background-color: $fbGrey__lightest;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
}

.ImageUpload_content {
  max-height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 4px auto 16px;
  padding: 18px 12px;

  div {
    display: flex;
    align-items: baseline;
  }
}

.ImageUpload__actionButton {
  color: $fbGreen__light;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  cursor: pointer;
  margin-right: 4px;

  &:hover {
    background: transparent;
    border: none;
    box-shadow: none;
  }
}

.UploadedImage__contentContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
}

.Image__contentContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.UploadedImage__contentLeft,
.Image__contentLeft {
  display: flex;
  align-items: center;
}

.ImageWrapper {
  width: 110px;
  height: 100px;
  border: 1px solid $fbGrey__lightest;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Spinner {
  color: $fbGreen__light;
}

.UploadedImage__image,
.Image {
  width: 100%;
  height: 100%;
  border-radius: $borderRadius__button;
  background-color: $fbGreen__light;
}

.UploadedImage__contentRight {
  width: 40%;
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.ImageCompany__name {
  min-width: 200px;
}

.LeanerProgress__root {
  width: 90%;
  height: 8px;
  border-radius: 10px;
  background: $fbGrey__light;
  margin-right: 8px;
}

.LeanerProgress__bar {
  background: $fbGreen__light;
}

.DeleteButton {
  border: none;
  background: transparent;
  cursor: pointer;
}

.DeleteIcon {
  color: $fbRed__light;
  padding: 16px;
  border-radius: 50%;
  transition: all $navigationTransitionTime;

  &:hover {
    background-color: $fbGrey__lightest;
  }
}

.ErrorMessage,
.ErrorMessage__upload {
  color: $fbRed__light;
  margin: -16px 0 0 4px;
}

.ErrorMessage__upload {
  margin-top: 0;
  margin-right: 4px;
}

.ErrorIcon {
  color: $fbRed__light;
}

.ErrorWrapper {
  display: flex;
  align-items: center;
}
