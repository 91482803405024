@import '../../../stylesheets/variables.scss';

.OrderThresholdDialogRoot {
  z-index: 1500 !important;
}

.OrderThresholdDialogPaper {
  margin: 20px !important;
  min-width: 90vw;
  max-width: 90vw;
  border: 2px solid $fbGrey__lightest;
  border-radius: $borderRadius__modal;
  min-height: 290px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;

  @media (min-width: $md) {
    min-width: 500px;
    max-width: 500px;
  }
}

.OrderThresholdDialogHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 20px;
}

.OrderThresholdDialogContent {
  width: 100%;
}

.OrderThresholdField {
  margin-top: 15px;
}

.OrderThresholdDialogAction {
  margin-bottom: 10px;
}

.OrderThresholdModalButton {
  position: absolute;
  right: 10px;
}

.SubmitLoadingSpinner {
  color: $fbGrey__light;

  svg {
    margin: 0 !important;
  }
}
