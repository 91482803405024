@import '../../../stylesheets/variables.scss';

.EditCustomPriceDialogRoot {
  z-index: 1500 !important;
}

.EditCustomPriceDialogPaper {
  margin: 20px !important;
  min-width: 90vw;
  max-width: 90vw;
  border: 2px solid $fbGrey__lightest;
  border-radius: $borderRadius__modal;

  @media (min-width: $md) {
    min-width: 370px;
    max-width: 470px;
  }
}

.EditCustomPriceDialog__header {
  background: white;
  border-radius: $borderRadius__modal $borderRadius__modal 0 0;
  color: $fbGrey__dark;
}

.EditCustomPriceDialog__closeBtn {
  position: absolute;
  right: 0;
  top: 0;
}

.InitialErrorContainer {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.InitialError {
  width: 400px;
  max-width: 90%;
}

.InitialError__content {
  margin-top: 12px;
}

.CustomPriceSummary {
  margin-bottom: 28px;
}

.CustomPriceSummary__row {
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
  margin-bottom: 8px;
}

.CustomPriceSummary__title {
  flex: 0.6;
  font: $font__bodyRegular;
  color: $fbGrey__dark;
  font-size: 18px;
  line-height: 140%;
}

.CustomPriceSummary__value {
  flex: 0.4;
  font: $font__bodyBold;
  color: $fbGrey__dark;
  font-size: 18px;
  line-height: 140%;
}

.SubmitBtn {
  margin: 0;
  margin-top: 19px;
  margin-left: 20px;
}

.FlexInputContainer {
  width: 100%;
  display: flex;
}

.ErrorContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.SubmitLoadingSpinner {
  line-height: 24px;
  width: 22px !important;
  height: 22px !important;
  color: $fbGrey__light;
}
