@import '../../../stylesheets/variables.scss';

.WTFCell {
  .Default {
    display: block;
  }

  .OnlyOnRowOver {
    display: none;
  }
}

.InvisibleBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 6px 8px;
  border: 1px solid transparent;
  outline: none;
  box-shadow: none;
  letter-spacing: normal;
  text-transform: none;
  font-variant: none;
  background: transparent;
}

.EditBtn {
  transition: all $navigationTransitionTime;
  border-radius: $borderRadius__modal;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 6px 8px;
  border: 1px solid transparent;
  outline: none;
  box-shadow: none;
  letter-spacing: normal;
  text-transform: none;
  font-variant: none;
  background: white;

  &.error {
    border: 1px solid $fbRed__standard;
  }

  &.fakeHover {
    background: white;
    cursor: default;
    border: 1px solid $fbGrey__standard;
    box-shadow: $darkBoxShadow;

    &.error {
      border: 1px solid $fbRed__standard !important;
    }
  }
}

.EditBtn__content {
  transition: all $navigationTransitionTime;
  padding: 0;
  font: $font__bodyRegular;
  color: $fbGrey__standard;
  line-height: 145%;
}

.EditBtn__editIcon {
  transition: all $navigationTransitionTime;
  color: $fbGrey__standard;
  height: 0;
  width: 0;
}

.EditBtn__addIcon {
  transition: all $navigationTransitionTime;
  color: $fbGrey__standard;
  height: 0;
  width: 0;
}

table {
  tr {
    transition: all $navigationTransitionTime;

    &:hover {
      .PriceCell {
        .Default {
          display: none;
        }

        .OnlyOnRowOver {
          display: block;
        }
      }

      .EditBtn {
        cursor: pointer;
        border: 1px solid $fbGrey__standard;
        box-shadow: $lightBoxShadow;
        background: white;

        &.fakeHover {
          background: white;
          cursor: default;
          border: 1px solid $fbGrey__standard;
          box-shadow: $darkBoxShadow;

          &.error {
            border: 1px solid $fbRed__standard !important;
          }
        }

        &:hover {
          background: white;
          border: 1px solid $fbGrey__standard;
          box-shadow: $darkBoxShadow;

          &.error {
            border: 1px solid $fbRed__standard !important;
          }
        }

        .EditBtn__editIcon {
          width: 20px;
          height: 20px;
          margin-left: 8px;
          padding-right: 4px;
        }

        .EditBtn__addIcon {
          width: 20px;
          height: 20px;
          margin-left: 28px;
          padding-right: 4px;
        }
      }
    }
  }

  th {
    transition: all $navigationTransitionTime;
  }

  td {
    transition: all $navigationTransitionTime;
  }
}

.WTFCellContent {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.WTFTooltip {
  font-size: 15px;

  .Arrow {
    position: absolute;
    font-size: 6;

    &::before {
      content: '';
      margin: auto;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 1em 1em 0 1em;
      border-color: $fbBlue__dark transparent transparent transparent;
    }

    bottom: 0;
    left: calc(50% - 1em);
    width: 2em;
    height: 1em;
  }
}

.TextWithTopMargin {
  margin-top: 12px;
}
