@import '../../../stylesheets/variables.scss';

.BreadcrumbsContainer {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 4px;
}

.Breadcrumb {
  display: flex;
  align-items: center;
}

.PreviousLink {
  text-decoration: none;
  cursor: pointer;
  border-bottom: 2px solid transparent;

  &a {
    border-bottom: 2px solid transparent;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      border-bottom: 2px solid $fbGrey__dark;
    }
  }

  &:hover {
    border-bottom: 2px solid $fbGrey__dark;
  }
}

.noMargin {
  margin: 0;
}

.SeparatorIcon {
  color: $fbGreen__light;
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 0;
  font-size: 30px;
}
