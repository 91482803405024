// Need to lint this file, but too many changes to do when installing this tool

/* stylelint-disable */

@import '../../stylesheets/variables.scss';

.NavigationContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1200;
}

.Navigation__AppBar {
  background: $fbGrey__dark;
  height: $mobileNavHeight;
  padding-left: 8px;
  padding-right: 8px;
  box-shadow: none;

  @media (min-width: $lg) {
    height: $desktopAppBarHeight;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.AppBar__content {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: $maxPageWidth;
  margin: auto;
  justify-content: space-between;

  &.Desktop {
    display: none;

    @media (min-width: $lg) {
      display: flex;
    }
  }

  &.Mobile {
    @media (min-width: $lg) {
      display: none;
    }
  }
}

.AppBarContent__left {
  width: unset;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  height: 100%;
  width: 43px;

  @media (min-width: $lg) {
    width: unset;
  }
}

.AppBarContent__right {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 43px;

  @media (min-width: $lg) {
    width: unset;
  }
}

.AppBarContent__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.AppBar__logo {
  padding: 4px;
  height: 20px;

  @media (min-width: $lg) {
    padding: 8px;
    height: 24px;
  }
}

.AppBar__desktopHeader {
  display: none;

  @media (min-width: $lg) {
    display: block;
    font-size: 22px;
    color: $fbGrey__lightest;
  }
}

.AppBar__dropdownBtn {
  color: $fbGrey__lightest;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  background: none;
  outline: none;
  margin: 0;
  font: $font__bodyRegular;
  color: $fbGrey__lightest;

  &:hover {
    color: $fbGreen__light;
  }

  &:focus {
    outline: 5px auto $fbGreen__light;
  }
}

.DesktopNavbarWrapper {
  transition: all $navigationTransitionTime;
  height: 0;
  overflow: hidden;
  position: fixed;
  background: white;
  border-bottom: 1px solid $fbGrey__lightest;

  @media (min-width: $lg) {
    margin-top: $desktopAppBarHeight;
    height: $desktopNavHeight;
    width: 100%;
    display: inherit;
  }
}

.DesktopNavbarBanner {
  height: 100%;
  max-width: 1230px;
  justify-content: space-between;
  margin: auto;
  width: 100%;
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
}

.DashboardNavbar__link {
  display: flex;
  align-items: center;
  font: $font__bodyRegular;
  color: $fbGrey__standard;
  text-decoration: none;
  line-height: 40px;
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
  border: 1px solid white;
  margin-right: 12px;
  max-width: 170px;

  &.disabled {
    cursor: not-allowed;
  }

  &:hover,
  &:active,
  &:focus {
    border: 1px solid $fbGrey__lightest;
    border-bottom: 1px solid white;
    border-radius: $borderRadius__button $borderRadius__button 0 0;
    color: $fbGrey__dark;
    text-decoration: none;
    position: relative;

    .DashboardNavbar__linkIcon {
      color: $fbGreen__light;
    }

    &.disabled {
      color: $fbGrey__standard;
      cursor: not-allowed;

      .DashboardNavbar__linkIcon {
        color: $fbGrey__light;
      }
    }

    &::after {
      content: '';
      position: absolute;
      background: white;
      width: 100%;
      height: 1px;
      bottom: -2px;
      left: 0;
    }
  }
}

.DashboardNavbar__link__active {
  border: 1px solid $fbGrey__lightest;
  border-bottom: 1px solid white;
  border-radius: $borderRadius__button $borderRadius__button 0 0;
  color: $fbGrey__dark;
  text-decoration: none;
  position: relative;

  .DashboardNavbar__linkIcon {
    color: $fbGreen__light;
  }

  &::after {
    content: '';
    position: absolute;
    background: white;
    width: 100%;
    height: 1px;
    bottom: -2px;
    left: 0;
  }
}

.DashboardNavbar__linkIcon {
  color: $fbGrey__light;
  margin-right: 5px;
}

.MenuLink {
  font: $font__bodyRegular;
  font-size: 18px;
  color: $fbGrey__standard;
  border-radius: $borderRadius__button;

  svg {
    color: $fbGrey__light;
  }

  &:focus {
    color: $fbGrey__standard;
    text-decoration: none;
    outline: none;
  }

  &:hover,
  &:active {
    text-decoration: none;

    svg {
      color: $fbGreen__light;
    }
  }

  svg {
    margin-right: 12px;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: auto;
    background: transparent;

    svg {
      color: $fbGrey__light;
    }
  }
}

.flipImageContent {
  display: flex;
  align-items: center;

  svg {
    transform: scaleX(-1);
  }
}

.MobileDrawerWrapper,
.MobileDrawerPaper,
.MobileDrawerModal {
  top: $mobileNavHeight !important;

  @media (min-width: $lg) {
    top: $desktopAppBarHeight !important;
  }
}

.MobileDrawerContainer {
  width: 260px;
  padding: 16px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.MobileDrawer__section {
  &:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }
}

.MobileDrawer__sectionContent {
  margin-top: 4px;
  margin-bottom: 4px;
}

.MobileDrawerLink {
  display: flex;
  align-items: center;
  font: $font__bodyRegular;
  color: $fbGrey__standard;
  font-size: 18px;
  text-decoration: none;
  box-sizing: border-box;
  padding: 16px 4px;

  .DashboardNavbar__linkIcon {
    margin-right: 12px;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &:hover,
  &:active,
  &:focus {
    color: $fbGrey__dark;
    text-decoration: none;

    .DashboardNavbar__linkIcon {
      color: $fbGreen__light;
    }

    &.disabled {
      color: $fbGrey__standard;
      cursor: not-allowed;

      .DashboardNavbar__linkIcon {
        color: $fbGrey__light;
      }
    }
  }
}

.MobileDrawerLink__active {
  color: $fbGrey__dark;
  text-decoration: none;

  .DashboardNavbar__linkIcon {
    color: $fbGreen__light;
  }
}
