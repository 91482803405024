@import '../../../stylesheets/variables.scss';

.HeaderContainer {
  background: white;
}

.Header {
  max-width: $maxPageWidth;
  margin: auto;
}

.HeaderContent {
  padding: 16px 12px;

  @media (min-width: $md) {
    padding: 20px 16px;
  }
}
