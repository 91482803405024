@import '../../stylesheets/variables.scss';

.ReportsContainer {
  overflow: hidden;

  @media (min-width: $md) {
    overflow: unset;
  }
}

.SubheadingContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 12px;
}

.ActionButton__icon {
  color: $fbGrey__light;
  margin-right: 8px;
}

.DownloadActionsContainer {
  overflow-x: scroll;
  white-space: nowrap;
  display: flex;
  margin-left: -12px;
  margin-right: -12px;

  @media (min-width: $md) {
    overflow-x: unset;
    white-space: unset;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
}

.PageContainer {
  max-width: $maxPageWidth;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.SalesChartContainer {
  display: flex;
  flex-direction: column;
  max-width: $maxPageWidth;
  width: 100%;
  justify-content: center;
  margin-top: 36px;
}

.ErrorWrapper {
  margin: auto;
  max-width: 94%;
  width: 400px;
}

.MonthlySnapshotContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;

  @media (min-width: $md) {
    width: 100%;
  }
}

.ReportSectionHeader {
  text-align: start;
  margin-top: 36px;
  margin-bottom: 24px;
  margin-right: auto;
}

.SnapShotCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: $md) {
    justify-content: space-between;
    flex-direction: row;
  }
}

.TopCustomersHeaderContainer {
  display: flex;
  align-items: center;
}

.TopCustomersContainer {
  display: flex;
  width: 90%;
  flex-direction: column;

  @media (min-width: $md) {
    width: 100%;
  }
}

.BestSellingItemsContainer {
  display: flex;
  width: 90%;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;

  @media (min-width: $md) {
    width: 100%;
  }
}

.TopProductsHeaderContainer {
  display: flex;
  align-items: center;
}

.InfoIcon {
  margin-left: 12px;
}

.SalesReportHeader {
  padding-bottom: 24px;
}

.MenuLink {
  font: $font__bodyRegular;
  font-size: 18px;
  color: $fbGrey__standard;
  border-radius: $borderRadius__button;

  &:focus {
    color: $fbGrey__standard;
    text-decoration: none;
    outline: none;
  }

  &:hover,
  &:active {
    text-decoration: none;
  }
}

.ActionBar__buttonsContainer {
  display: flex;
}

.ActionBar__button {
  height: 41px;
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  margin: 8px 8px 8px 0;
}

.EmptyPaper {
  margin-top: 12px;
  display: flex;
}

.EmptyRow {
  margin: 0;
  margin-left: 12px;
  color: $fbGrey__standard;
  padding: 0;
}

.ErrorIcon {
  right: 8px;
  top: 8px;
  color: $fbRed__standard;
  margin-left: auto;
}
