@import '../../../../stylesheets/variables.scss';

.EditDeliveryDaysModal__paper {
  margin: 20px !important;
  position: relative;
  overflow: visible;

  @media (min-width: $md) {
    min-width: 600px;
    max-width: unset;
    margin: inherit;
  }
}

.EditDeliveryDaysModal__root {
  z-index: 1500 !important;
}

.EditDeliveryDaysModal__header {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (min-width: $md) {
    padding: 24px;
  }
}

.EditDeliveryDaysModalHeader__actions {
  display: flex;
  flex: 1;
  justify-content: center;
  padding-top: 16px;
  @media (min-width: $md) {
    justify-content: flex-end;
    padding-top: 0;
  }

  button {
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 0;
    &:last-child {
      margin-right: 0;
    }
  }
}

.EditDeliveryDaysModal__title {
  display: flex;
  padding: 0;
}

.FormFieldErrorContainer {
  min-height: 24px;
  padding: 4px 8px;
  font: $font__bodyRegular;
  font-size: 12px;
  line-height: 140%;
  color: $fbRed__standard !important;
}

.ErrorContainer {
  display: flex;
  justify-content: center;
}

.EditDeliveryDaysModal__content {
  padding: 12px 30px 40px 30px;
  overflow: visible;
}

.DeliveryDaysTable {
  width: 100%;

  th {
    color: $fbGrey__standard;
    font: $font__bodyBold;
    font-size: 18px;
    &.PrimaryHeading {
      color: $fbGrey__dark;
      font: $font__headingBold;
      font-size: 18px;
    }
    &.SecondaryHeading {
      &::after {
        content: '👇';
      }
    }
  }

  td,
  th {
    max-width: 220px;
    text-align: left;
    padding: 10px 14px 10px 10px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  tr {
    &.ContentRow {
      border-bottom: 1px solid $fbGrey__lightest;
      color: $fbGrey__standard;
      font-size: 18px;
      &.active {
        font: $font__bodyBold;
      }
      &.inactive {
        font: $font__bodyRegular;
      }
    }
  }
}

.DayLabel {
  color: $fbGrey__standard;
  font: $font__bodyRegular;
  font-size: 18px;
  &.active {
    font: $font__bodyBold;
    font-size: 18px;
  }
}

.DisabledDayDash {
  &::before {
    content: '-';
    font: $font__bodyBold;
    font-size: 18px;
    color: $fbGrey__light;
  }
}

.DayStatusChip {
  margin: 0;
}

.ButtonLoadingSpinner {
  width: 24px !important;
  height: auto !important;
}

.Switch__formControl {
  margin-left: 8px;
  margin-right: 8px;
  &.horizontal {
    width: 90px;
  }
}

.Switch__label {
  font: $font__headingBold;
  font-size: 16px;
  color: $fbGrey__dark;
  line-height: 140%;
  margin-bottom: 4px;
  &.horizontal {
    margin-bottom: 0;
    margin-left: 8px;
  }
}

.Switch__root {
  width: 36px;
  height: 18px;
  display: flex;
  align-items: center;
  padding: 2px;
  margin-left: 8px;
  margin-right: 4px;
}

.Switch__switchBase {
  padding: 0;
  margin-left: 5px;
  margin-top: 3px;
  color: $fbGrey__standard;
  &.checked {
    transform: translateX(14px);
    color: $fbGreen__standard;
  }
}

.Switch__thumb {
  width: 12px;
  height: 12px;
  box-shadow: none;
}

.Switch__track {
  border-radius: 8px;
  opacity: 1;
  background: transparent;
  border: 2px solid $fbGrey__standard;
  &.checked {
    opacity: 1 !important;
    background: transparent !important;
    border: 2px solid $fbGreen__standard;
  }
}

.SameDayWarning {
  font: $font__bodyRegular;
  font-size: 14px;
  color: $fbYellow__standard;
}
