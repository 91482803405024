@import '../../../../stylesheets/variables.scss';

.EditDeliverySuburbsModal__paper {
  margin: 20px !important;
  position: relative;

  @media (min-width: $md) {
    min-width: 600px;
    max-width: 600px;
    margin: inherit;
  }
}

.EditDeliverySuburbsModal__root {
  z-index: 1500 !important;
}

.EditDeliverySuburbsModal__header {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (min-width: $md) {
    padding: 24px;
  }
}

.EditDeliverySuburbsModalHeader__actions {
  display: flex;
  flex: 1;
  justify-content: center;
  padding-top: 16px;
  @media (min-width: $md) {
    justify-content: flex-end;
    padding-top: 0;
  }

  button {
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 0;
    &:last-child {
      margin-right: 0;
    }
  }
}

.EditDeliverySuburbsModal__title {
  display: flex;
  padding: 0;
}

.ErrorContainer {
  display: flex;
  justify-content: center;
}

.EditDeliverySuburbsModal__content {
  min-height: 500px;
  padding: 12px 30px 40px 30px;
}

.ButtonLoadingSpinner {
  width: 24px !important;
  height: auto !important;
}

.PostcodeSectionCard {
  border-radius: $borderRadius__modal;
  background: white;
  border: 1px solid $fbGrey__lightest;
  padding: 16px;
  margin-bottom: 24px;
  position: relative;
  &:first-child {
    margin-top: 24px;
  }
}

.PostcodeSectionCard__suburb {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.PostcodeSectionCard__postcodeText {
  color: $fbGrey__dark;
  margin: 0;
}

.PostcodeSectionCard__suburbText {
  margin: 0;
}

.PostcodeSectionCard__moreBtn {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 12px;
}

.SuburbStatusChip {
  margin: 4px 8px 4px 0;
}

.PostcodeSwitchContainer {
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
}

.SuburbSwitchContainer {
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
}

.FormFieldErrorContainer {
  min-height: 24px;
  padding: 4px 8px;
  font: $font__bodyRegular;
  font-size: 12px;
  line-height: 140%;
  color: $fbRed__standard !important;
}
