@import '../../../stylesheets/variables.scss';

.DialogRoot {
  z-index: 1500 !important;
}

.DialogPaper {
  margin: 20px !important;
  min-width: 90vw;
  max-width: 90vw;
  border: 2px solid $fbGrey__lightest;
  border-radius: $borderRadius__modal;

  @media (min-width: $md) {
    min-width: 500px;
    max-width: 500px;
  }

  text-align: center;
}

.DownloadingPDFsDialog__title {
  justify-content: center;
}

.DownloadingPDFsDialog__content {
  justify-content: center;
}

.ButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 12px;
}

.OkButton {
  @media (min-width: $md) {
    min-width: 150px;
  }
}
