@import '../../stylesheets/variables.scss';

.OrderThresholdContainer {
  margin: 16px auto;
  max-width: 730px;
  padding: 12px 20px;

  @media (min-width: $md) {
    margin-bottom: 28px;
  }
}

.OrderThresholdWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.OrderThresholdContent {
  display: flex;
  flex-direction: column;

  @media (min-width: $md) {
    align-items: center;
    flex-direction: row;
  }
}

.OrderThresholdTitle {
  margin-right: 20px;
}

.OrderThreshold__amountText {
  margin-top: 10px;
  color: $fbGrey__standard;
}

.OrderThresholdActionButton {
  margin: 0.1px;
}
