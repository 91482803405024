@import '../../stylesheets/variables.scss';

.CloseBtn {
  position: absolute;
  top: 8px;
  right: 8px;
}

.DownloadProductsDialog__title {
  padding: 16px 32px 16px 16px;

  @media (min-width: $md) {
    padding: 24px 48px 24px 18px;
  }
}

.DownloadProductsDialog__content {
  transition: all $navigationTransitionTime;
  padding: 0 16px 16px 16px;

  @media (min-width: $md) {
    padding: 0 24px 24px 24px;
  }

  &.expand {
    min-height: 750px;
  }
}

.DownloadProductsDialog__subheader {
  padding-top: 24px;
  padding-bottom: 24px;
}

.APIErrorContainer {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.APIError {
  width: 400px;
  max-width: 90%;
}

.APIError__content {
  margin-top: 12px;
}

.DownloadCSVForm {
  margin-top: 40px;
}

.InputRow {
  flex-wrap: wrap;
  width: 100%;
  display: flex;

  @media (min-width: $md) {
    flex-wrap: nowrap;
  }
}

.FullWidthInputContainer {
  width: 100%;
}

.Input__halfWidth {
  width: 100%;

  @media (min-width: $md) {
    flex: 1;

    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }
}

.Input__fullWidth {
  width: 100%;
}

.DateInputsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BetweenDatesIcon {
  margin-top: 20px;
  color: $fbGrey__light;
}

.DateInput {
  width: 100%;

  &:first-child {
    margin-right: 6px;
  }

  &:last-child {
    margin-left: 6px;
  }
}

.InputLabel {
  margin-left: 8px;
  font: $font__bodyRegular;
  font-size: 14px;
  line-height: 140%;
  color: $fbGrey__standard;

  &.error {
    color: $fbRed__standard !important;
  }
}

.InputField {
  padding: 12px 16px;
  border-radius: $borderRadius__button;
  background: white !important;
  font: $font__bodyRegular;
  color: $fbGrey__dark;
  font-size: 16px;
  line-height: 140%;
  border: 1px solid $fbGrey__standard;
  box-shadow: $lightBoxShadow;
  width: 100%;
  outline: none;
  position: relative;
  box-sizing: border-box;

  &::placeholder {
    color: $fbGrey__light !important;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.focused,
  &:focus {
    outline: 1px auto $fbGreen__light;
  }
}

.ErrorMessageContainer {
  min-height: 24px;
  padding: 4px 8px;
  font: $font__bodyRegular;
  font-size: 12px;
  line-height: 140%;
  color: $fbRed__standard !important;
}

.DownloadProductsDialog__root {
  z-index: 1500 !important;
}

.DownloadProductsDialog__paper {
  margin: 20px !important;
  position: relative;

  @media (min-width: $md) {
    margin: inherit;
    min-width: 600px;
  }
}

.DownloadOrderDialog__actions {
  transition: all $navigationTransitionTime;
  margin-top: 20px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &.expand {
    margin-top: 80px;
  }
}

.ErrorContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.SubmitBtn {
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
}

.SubmitLoadingSpinner {
  line-height: 24px;
  width: 22px !important;
  height: 22px !important;
  color: $fbGrey__light;
}

.Link {
  text-decoration: underline;
  color: $fbGrey__dark;

  &:hover {
    color: $fbGrey__standard;
  }
}
