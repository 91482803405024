@import '../../stylesheets/variables.scss';

.FloatingBannerContainer {
  width: 100%;
  position: fixed;
  bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  border: none;
  outline: none;
  background: none;
  margin: none;
  padding: none;
  cursor: pointer;

  &.hide {
    width: 48px;
  }
}

.FloatingBanner {
  transition: all $navigationTransitionTime;
  width: 85vw;
  border-radius: $borderRadius__modal;
  font: $font__headingMedium;
  font-size: 16px;
  text-align: center;
  color: white;
  padding: 24px;
  background: $fbGrey__light;
  box-shadow: $darkBoxShadow;
  position: relative;

  &.hide {
    width: 48px;
    padding-right: 0;
  }
}

.FloatingBannerContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.RainbowBackground {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 8px;
  background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
  background-size: 1800% 1800%;
  animation: rainbow 18s ease infinite;
}

@keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }

  50% {
    background-position: 100% 19%;
  }

  100% {
    background-position: 0% 82%;
  }
}
