@import '../../../stylesheets/variables.scss';

.DeliveryZones__content {
  flex: 1;
  overflow-y: scroll;
  padding-bottom: 60px;

  @media (min-width: $lg) {
    margin-left: 424px;
    width: calc(100vw - 424px);
  }
}

.DeliveryZones__PageHeaderContainer {
  background-color: white;
}

.DeliveryZones__PageHeader {
  display: flex;
  justify-content: space-between;
  padding: 32px 24px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}

.ActionBar {
  display: flex;
  flex-wrap: wrap;

  .CreateZone {
    margin-left: 0;
  }
}

.DeliveryZoneNameContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.SelectedDeliveryZone__Name {
  margin-bottom: 15px;
  max-width: 600px;
}

.DeliveryZones__SectionContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DeliveryZones__SectionColumn {
  width: 100%;
  @media (min-width: $md) {
    width: auto;
    min-width: 415px;
    max-width: 500px;
    flex: 1;
  }
  .DetailsPaper {
    box-shadow: $elevatedShadow;
    margin: 16px;
    overflow: scroll;
    @media (min-width: $md) {
      margin: 32px;
    }
  }
  &:first-child {
    .DetailsPaper {
      @media (min-width: $md) {
        margin-right: 16px;
      }
    }
  }
  &:last-child {
    .DetailsPaper {
      @media (min-width: $md) {
        margin-left: 16px;
      }
    }
  }
}

.DetailsPaper__header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.DetailsPaper__content {
  margin-top: 24px;
}

.DeliveryZones__Section {
  flex: 1;
  margin: 24px;
}

.DeliveryDetails__headerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.EditButton {
  margin: 0;
}

.DetailsRow {
  color: $fbGrey__standard;
  margin-bottom: 16px;
}

.ButtonLoadingSpinner {
  width: 24px !important;
  height: auto !important;
}

.EditZoneNameBtn {
  padding: 12px;
  margin-left: 4px;
  margin-bottom: 12px;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
}

.NameForm {
  display: flex;
  align-items: center;
}

.NameChangeField {
  box-sizing: border-box;
  background: white !important;
  border-bottom: 1px solid $fbGrey__standard;
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none;
  padding: 0 12px 12px 0;
  width: 100%;
  outline: none;
  position: relative;
  font: $font__headingBold;
  font-size: 26px;
  color: $fbGrey__dark;
  margin-bottom: 0;
  line-height: 140%;

  @media (min-width: $md) {
    font-size: 32px;
    margin-bottom: 0;
    line-height: 140%;
  }

  @media (min-width: $lg) {
    font-size: 36px;
    margin-bottom: 0;
    line-height: 140%;
  }

  &::placeholder {
    color: $fbGrey__light !important;
  }
  &.errored {
    border-bottom: 2px solid $fbRed__standard;
  }
  &.disabled {
    cursor: not-allowed;
  }
  &:focus {
    outline: none;
    border-bottom: 2px solid $fbGreen__light;
  }
}

.NameFormButtons {
  display: flex;
  align-content: center;
  margin-bottom: 12px;
}

.NameFormButton {
  outline: none;
  border: none;
  background: none;
  margin: 0;
  cursor: pointer;
  padding: 12px;
  &:last-child {
    margin-right: 8px;
  }
  .NameFormButton__icon {
    color: $fbGrey__standard;
    width: 24px;
    height: 24px;
    &.spin {
      animation: spin 600ms infinite linear;
    }
  }
  &.submit {
    &:hover {
      .NameFormButton__icon {
        color: $fbGreen__light;
      }
    }
  }
  &.cancel {
    &:hover {
      .NameFormButton__icon {
        color: $fbRed__light;
      }
    }
  }
  &:disabled {
    cursor: not-allowed;
    &:hover {
      .NameFormButton__icon {
        color: $fbGrey__standard;
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.GreyStandard {
  color: $fbGrey__standard;
}
