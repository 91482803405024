@import '../../stylesheets/variables.scss';

.CSVProductsTable__errormsg {
  margin-top: 5px;
  color: $fbRed__dark;
  font-size: 12px;
}

.CSVProductsTable__tablePaginationContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.CSVProductsTable__tablePaginationSpacer {
  display: none !important;
}

.CategoryEditCell {
  width: 100%;
  display: flex;
}

.CategoryEditCell__actionBtn {
  margin-top: -8px;
  margin-bottom: -8px;
}

.CategoryEditCell__selectField {
  font-size: 13px;
}

.CategoryEditCell__selectField__disabled {
  opacity: 0.5;
  font-size: 13px;
}

.ErrorIcon {
  color: $fbRed__standard;
}

.ProductNameTextField {
  width: 220px;
}
