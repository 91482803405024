@import '../../../../stylesheets/variables.scss';

.DeliveryDaysTable {
  width: 100%;

  th {
    color: $fbGrey__standard;
    font: $font__bodyBold;
    font-size: 18px;
    &.PrimaryHeading {
      color: $fbGrey__dark;
      font: $font__headingBold;
      font-size: 18px;
    }
    &.SecondaryHeading {
      &::after {
        content: '👇';
      }
    }
  }

  td,
  th {
    text-align: left;
    padding: 10px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  tr {
    &.ContentRow {
      border-bottom: 1px solid $fbGrey__lightest;
      color: $fbGrey__standard;
      font-size: 18px;
      &.active {
        font: $font__bodyBold;
      }
      &.inactive {
        font: $font__bodyRegular;
      }
    }
  }
}

.DisabledDayDash {
  &::before {
    content: '-';
    font: $font__bodyBold;
    font-size: 18px;
    color: $fbGrey__light;
  }
}

.DayStatusChip {
  margin: 0;
}
