@import '../../stylesheets/variables.scss';

.PageContainer {
  padding-bottom: 80px;
}

.SpecialsHeading {
  display: flex;
  align-items: center;
}

.ActionBar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ActionBar__buttonsContainer {
  display: flex;
}

.ActionBar__button {
  height: 41px;
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  margin: 8px 8px 8px 0;
}

.DropdownIcon {
  margin-left: -4px;
}

.MenuLink {
  font: $font__bodyRegular;
  font-size: 18px;
  color: $fbGrey__standard;
  border-radius: $borderRadius__button;

  &:focus {
    color: $fbGrey__standard;
    text-decoration: none;
    outline: none;
  }

  &:hover,
  &:active {
    text-decoration: none;
  }
}

.SearchBarContainer {
  padding-top: 8px;
  width: 450px;
}

.TableContainer {
  padding: 20px;
}

.SpecialsTable {
  td,
  th {
    width: 150px;
    padding-right: 4px;
    padding-left: 8px;

    &.SKU {
      width: 76px;
    }

    &.ProductName {
      width: 220px;
    }

    &.SupplierName {
      width: 220px;
    }

    &.Date {
      width: 110px;
    }

    &.Price {
      width: 115px;
    }
  }
}

.NoResultsContainer {
  text-align: center;
  margin: auto;
  margin: 16px;
  padding: 12px;

  @media (min-width: $sm) {
    margin: 22px 12px 22px 12px;
    padding: 25px;
  }
}

.NoResultsActions {
  display: flex;
  justify-content: center;
}

.TypographySection {
  padding-top: 12px;
  padding-bottom: 12px;
}

.TypographyBody {
  margin-bottom: 8px;
}

.TableBtn {
  margin: 0;
  margin-right: 12px;
}

.ErrorWrapper {
  margin: auto;
  max-width: 94%;
  width: 400px;
}

.RemainingCountMessagePaper {
  margin: auto;
  border: 2px solid $fbGrey__dark;
  position: relative;
  width: 800px;
  max-width: 90vw;
  margin-top: 40px;
  margin-bottom: 20px;

  @media (min-width: $md) {
    padding: 44px;
  }
}

.RemainingCountMessage__closeBtn {
  position: absolute;
  top: 0;
  right: 0;
}

.RemainingCountMessage__heading {
  text-align: center;
}

.RemainingCountMessageBlock {
  padding-bottom: 20px;
}

.RemainingCountMessageList {
  margin-left: 20px;
  list-style: disc;
}

.BlockHeading {
  margin-bottom: 16px;

  @media (min-width: $md) {
    margin-bottom: 24px;
  }
}

.CloseIcon {
  color: $fbGrey__light;
  width: 1.2em;
  height: 1.2em;
}

.Bold {
  font-weight: bold;
}
