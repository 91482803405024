@import '../../stylesheets/variables.scss';

.ProductImageDialogPaper {
  margin: 20px !important;

  @media (min-width: $md) {
    margin: inherit;
  }
}

.ProductImageDialogRoot {
  z-index: 1500 !important;
}

.ProductImageDialog__title {
  padding: 0;
  text-align: right;
}

.ProductImage {
  max-width: 100%;
}
