@import '../../../../stylesheets/variables.scss';

.EditDeliveryDetailsModal__paper {
  margin: 20px !important;
  position: relative;

  @media (min-width: $md) {
    min-width: 600px;
    margin: inherit;
  }
}

.EditDeliveryDetailsModal__root {
  z-index: 1500 !important;
}

.EditDeliveryDetailsModal__title {
  padding: 16px 32px 16px 16px;

  @media (min-width: $md) {
    padding: 24px 48px 24px 24px;
  }
}

.CloseBtn {
  position: absolute;
  top: 8px;
  right: 8px;
}

.InputRow {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
}

.Input__fullWidth {
  width: 100%;
}

.SubmitBtn__container {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.ErrorContainer {
  display: flex;
  justify-content: center;
}

.ButtonLoadingSpinner {
  width: 24px !important;
  height: auto !important;
}
