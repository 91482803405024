@import '../../stylesheets/variables.scss';

.ActionBar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ActionBar__buttonsContainer {
  display: flex;
}

.SearchBarContainer {
  padding-top: 8px;
  width: 450px;
}

.ErrorWrapper {
  margin: auto;
  max-width: 94%;
  width: 400px;
}

.TableContainer {
  padding: 20px;
}

.TableBtn {
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
}

.ReviewColumnChild {
  display: flex;
  overflow: hidden;
}

.ReviewsTable {
  td,
  th {
    width: 150px;
    padding-right: 4px;
    padding-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.ID {
      width: 50px;
      min-width: 50px;
    }

    &.Rating {
      width: 100px;
    }

    &.Review {
      width: 250px;
    }

    &.Customer {
      width: 140px;
    }

    &.Status {
      width: 100px;
    }

    &.CreatedAt {
      width: 160px;
    }

    &.Reply {
      width: 220px;
    }
  }

  td {
    &.TruncatedText {
      transition: all 650ms cubic-bezier(0.25, 1.7, 0.35, 0.8);
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(251, 251, 251, 0) 69%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    }
  }

  tr {
    &:hover {
      td {
        &.TruncatedText {
          transition: all 650ms cubic-bezier(0.25, 1.7, 0.35, 0.8);
          position: relative;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(251, 251, 251, 0) 69%,
              rgba(247, 247, 247, 1) 100%
            );
          }
        }
      }
    }
  }
}

.NoResultsContainer {
  text-align: center;
  margin: auto;
  margin: 16px;
  padding: 12px;

  @media (min-width: $sm) {
    margin: 22px 12px 22px 12px;
    padding: 25px;
  }
}

.NoResultsActions {
  display: flex;
  justify-content: center;
}

.TypographySection {
  padding-top: 12px;
  padding-bottom: 12px;
}

.TypographyBody {
  margin-bottom: 8px;
}

.ReviewsWelcomePaper {
  margin: auto;
  position: relative;
  width: 800px;
  max-width: 90vw;
  margin-top: 40px;
  margin-bottom: 20px;

  @media (min-width: $md) {
    padding: 44px;
  }
}

.ReviewsWelcomePaper__heading {
  text-align: center;
  margin-bottom: 20px;
}

.ReviewsWelcomePaper__body {
  text-align: center;
}

.ReviewsWelcomePaperBlock {
  &.Padded {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.GreenLink {
  font: $font__bodyRegular;
  font-size: 14px;
  color: $fbGreen__standard;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    color: $fbGreen__dark;
  }
}
