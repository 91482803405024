@import '../../../stylesheets/variables.scss';

.ProductRemovalDialogRoot {
  z-index: 1500 !important;
}

.ProductRemovalDialogPaper {
  margin: 20px !important;
  min-width: 90vw;
  max-width: 90vw;
  border: 2px solid $fbGrey__lightest;
  border-radius: $borderRadius__modal;
  min-height: 450px;
  padding: 8px;

  @media (min-width: $md) {
    min-width: 572px;
    max-width: 572px;
  }
}

.ProductRemovalDialogContent {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  min-height: 72px;
  max-height: 72px;
  justify-content: space-between;

  div {
    text-align: left;
  }
}

.ProductRemovalDialogButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 36px;

  button {
    width: 30%;

    svg {
      margin: 0;
    }
  }
}

.ProductRemovalDialog__closeBtn {
  position: absolute;
  right: 20px;
  top: 16px;
}

.InputRow {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: $md) {
    flex-wrap: nowrap;
  }
}

.DialogContent {
  display: flex;
  width: 100%;
}

.FullWidthInputContainer {
  width: 100%;
}
