@import '../../../stylesheets/variables.scss';

.RakeDialogRoot {
  z-index: 1500 !important;
}

.RakeDialogPaper {
  margin: 20px !important;
  min-width: 90vw;
  max-width: 90vw;
  border: 2px solid $fbGrey__lightest;
  border-radius: $borderRadius__modal;

  @media (min-width: $md) {
    min-width: 650px;
    max-width: 650px;
  }
}

.RakeDialog__header {
  background: white;
  border-radius: $borderRadius__modal $borderRadius__modal 0 0;
  color: $fbGrey__dark;
}

.RakeDialog__closeBtn {
  position: absolute;
  right: 0;
  top: 0;
}

.CustomCommissionForm {
  margin-top: 40px;
}

.CustomCommissionForm__actions {
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
}

.APIErrorContainer {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.APIError {
  width: 400px;
  max-width: 90%;
}

.APIError__content {
  margin-top: 12px;
}

.APIErrorMessage {
  color: $fbRed__standard;
  text-align: center;
  margin: 10px 0;
}

.APIErrorMessage__text {
  color: $fbRed__standard;
}

.ErrorMessageContainer {
  min-height: 24px;
  padding: 4px 8px;
  font: $font__bodyRegular;
  font-size: 12px;
  line-height: 140%;
  color: $fbRed__standard !important;
}

.InputRow {
  flex-wrap: wrap;
  width: 100%;
  display: flex;

  @media (min-width: $md) {
    flex-wrap: nowrap;
  }
}

.FullWidthInputContainer {
  width: 100%;
}

.SubmitBtn {
  margin: 30px 0 0 12px;
  height: 54px;
  width: 74%;
}

.SaveBtn {
  margin: 13px 0 0 12px;
  height: 44px;
}

.CancelBtn {
  height: 54px;
  margin-top: 30px;
}

.NotificationSpaceBlocker {
  padding-top: 44px;
}

.InputField {
  padding: 12px 16px;
  border-radius: $borderRadius__button;
  background: white !important;
  font: $font__bodyRegular;
  color: $fbGrey__dark;
  font-size: 16px;
  line-height: 140%;
  box-shadow: $lightBoxShadow;
  width: 100%;
  outline: none;
  border: none;
  position: relative;
  box-sizing: border-box;

  &::placeholder {
    color: $fbGrey__light !important;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.focused,
  &:focus {
    outline: 1px auto $fbGreen__light;
  }
}

strong {
  font-weight: bold !important;
}

.ErrorContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.SubmitLoadingSpinner {
  line-height: 24px;
  width: 22px !important;
  height: 22px !important;
  color: $fbGrey__light;

  svg {
    margin: 0 !important;
  }
}

.ConfirmDialog_header {
  margin: -50px 0 30px 0;
  width: 100%;
  text-align: center;
}

.ConfirmDialog_text {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ConfirmDialog_confirmText {
  width: 100%;
  text-align: center;
}

.ConfirmDialog_actions {
  display: flex;
  width: 100%;
}
