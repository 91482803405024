@import '../../../stylesheets/variables.scss';

.DialogRoot {
  z-index: 1500 !important;
}

.DialogPaper {
  margin: 20px !important;
  min-width: 90vw;
  max-width: 90vw;
  border: 2px solid $fbGrey__lightest;
  border-radius: $borderRadius__modal;

  @media (min-width: $md) {
    min-width: 500px;
    max-width: 500px;
  }
}

.EmailDownloadPDFDialog__header {
  background: white;
  border-radius: $borderRadius__modal $borderRadius__modal 0 0;
  color: $fbGrey__dark;
  text-align: center;
}

.HeadingEmoji {
  font-size: 32px;
}

.EmailDownloadPDFDialog__closeBtn {
  position: absolute;
  right: 0;
  top: 0;
}

.ButtonContainer {
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: $md) {
    flex-direction: row;
  }
}

.SubmitBtn {
  flex: 1;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}

.CancelBtn {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;

  @media (min-width: $md) {
    margin-right: 12px;
  }
}

.BodyText {
  margin-bottom: 12px;
}

.SuccessContainer {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}

.SubmitLoadingSpinner {
  color: $fbGrey__light;

  svg {
    margin: 0 !important;
  }
}
