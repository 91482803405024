@import '../../stylesheets/variables.scss';

.LoginForm {
  padding-top: 32px;
}

.LoginForm__submitting {
  cursor: progress;
}

.SubHeading {
  color: $fbGreen__light;
}

.LoginForm__formContent {
  display: flex;
  flex-wrap: wrap;

  @media (min-width: $md) {
    flex-direction: column-reverse;
  }
}

.LoginForm__inputs {
  flex-wrap: wrap;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;

  @media (min-width: $md) {
    flex-wrap: nowrap;
  }
}

.Login__inputContainer {
  width: 100%;

  @media (min-width: $md) {
    width: 320px;

    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }
}

.ErrorList {
  text-align: center;
}

.LoginForm__footer {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-direction: column-reverse;

  @media (min-width: $md) {
    flex-direction: row;
  }
}

.SubmitBtn {
  margin: 0;
}

.SubmitBtn__loading {
  color: $fbGrey__standard;

  svg {
    margin: 0 !important;
  }
}

.BoldOrangeLink {
  font: $font__bodyRegular;
  font-size: 18px;
  line-height: 145%;
  color: $fbGrey__standard;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    color: $fbGrey__dark;
  }
}

.GreenLink {
  font: $font__bodyRegular;
  font-size: 14px;
  color: $fbGreen__standard;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    color: $fbGreen__dark;
  }
}

.PasswordField__endAdornment {
  padding: 8px;
}

.CheckEmailButtonContainer {
  display: flex;
  justify-content: center;
}

.LoginForm__unverified {
  padding: 20px;
}

.Wrapper {
  margin-top: 20vh;
  background: $fbGrey__lightest;
  border-radius: $borderRadius__modal;
  padding: 16px;

  @media (min-width: $md) {
    padding: 32px;
  }
}
