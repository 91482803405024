@import '../../../stylesheets/variables.scss';

.CustomSubstitutionForm {
  margin-top: 40px;
}

.AddCustomSubstitutionDialogRoot {
  z-index: 1500 !important;
}

.AddCustomSubstitutionDialogPaper {
  margin: 20px !important;
  min-width: 90vw;
  max-width: 90vw;
  border: 2px solid $fbGrey__lightest;
  border-radius: $borderRadius__modal;
  min-height: 600px;

  @media (min-width: $md) {
    min-width: 650px;
    max-width: 650px;
  }
}

.AddCustomSubstitutionDialog__header {
  background: white;
  border-radius: $borderRadius__modal $borderRadius__modal 0 0;
  color: $fbGrey__dark;
}

.AddCustomSubstitutionDialog__closeBtn {
  position: absolute;
  right: 0;
  top: 0;
}

.CustomSubstitutionForm__actions {
  margin-top: 40px;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
}

.InputRow {
  flex-wrap: wrap;
  width: 100%;
  display: flex;

  @media (min-width: $md) {
    flex-wrap: nowrap;
  }
}

.FullWidthInputContainer {
  width: 100%;
}

.SubmitBtn {
  margin: 0;
}

.FinalPriceContainer {
  margin-top: 28px;
}
