@import '../../../stylesheets/variables.scss';

.DeliveryZoneIndexCard {
  cursor: pointer;
  padding: 16px;
  width: 100%;
  background-color: $fbGrey__lightest;
  margin-bottom: 12px;
  border-radius: $borderRadius__modal;
  box-shadow: $lightBoxShadow;
  border: 2px transparent solid;

  &.Selected {
    border: 2px $fbGrey__standard solid;

    &:hover {
      border: 2px $fbGrey__standard solid;
    }
  }

  &.Published {
    background-color: white;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    border: 2px $fbGrey__light solid;
    box-shadow: $elevatedShadow;
  }
}

.HeaderRow {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 12px;
  overflow: hidden;
}

.DeliveryZoneNameContainer {
  position: relative;
  min-width: 0;
}

.DeliveryZoneName {
  margin-bottom: 0;
  color: $fbGrey__standard;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.Published {
    color: $fbGrey__dark;
  }
}

.DetailsRow {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  flex-wrap: wrap;
  justify-content: flex-end;
  @media (min-width: $lg) {
    justify-content: flex-start;
  }
}

.DeliveryDays {
  display: flex;
  align-items: center;
}

.OnStatusChip {
  width: 40px;
  margin-left: 0;
}

.DeliveryDetails__Elements {
  color: $fbGrey__light;

  &.Selected {
    color: $fbGrey__standard;
  }

  &.Published {
    color: $fbGrey__standard;
  }
}

.DeliveryDetailsSpan {
  margin-left: 8px;
  margin-right: 8px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.SuburbCount {
  color: $fbGrey__light;

  &.Selected {
    color: $fbGrey__standard;
  }

  &.Published {
    color: $fbGreen__standard;
  }
}
