@import '../../../../stylesheets/variables.scss';

.NoResultsContainer {
  padding: 16px;
  text-align: center;
}

.NoResultsHeading {
  padding-top: 16px;
}

.PostcodeSectionCard {
  border-radius: $borderRadius__modal;
  background: white;
  border: 1px solid $fbGrey__lightest;
  padding: 16px;
  margin-bottom: 24px;
}

.PostcodeSectionCard__postcode {
  color: $fbGrey__standard;
}

.InvisibleButton {
  border: none;
  outline: none;
  background: white;
  padding: 0;
  margin: 0;
  border-radius: $borderRadius__modal;
  cursor: pointer;
  &:hover,
  &:focus {
    opacity: 0.75;
  }
}

.PostcodeSectionCard__suburb {
  display: flex;
  align-items: center;
}

.SuburbStatusChip {
  margin: 4px 8px 4px 0;
}
