@import '../../../stylesheets/variables.scss';

.ReviewDialog__root {
  z-index: 1500 !important;
}

.ReviewDialog__paper {
  padding: 34px;
  max-width: 90vw;
  width: 400px;
  margin: 12px;
  border-radius: $borderRadius__modal;

  @media (min-width: $md) {
    width: 520px;
  }
}

.ReviewDialog__titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CloseButton {
  margin-right: -20px;
  margin-top: -20px;
}

.ReviewIdContainer {
  color: $fbGrey__standard;
  padding-bottom: 16px;
}

.ReviewDialog__content {
  padding-top: 16px;
}

.ErrorWrapper {
  display: flex;
  justify-content: center;
}

.LoadingSpinnerContainer {
  padding-bottom: 20px;
}

.GreyPaper {
  background: $fbGrey__lightest;
  padding: 18px 22px;
  border-radius: $borderRadius__modal;
  margin-bottom: 24px;
}

.ReviewReplyDateContainer {
  padding-bottom: 8px;
}

.ReplyHeadingContainer {
  display: flex;
}

.ReplyHeadingText {
  margin-right: 5px;
}

.ReplyTooltipContainer {
  background-color: $fbGreen__dark;
  padding: 10px 20px;
  border-radius: 8px;
}

.ReplyTooltipMainText {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.ReplyTooltipBullets {
  list-style-type: disc;
  margin-top: 10px;
  margin-left: 30px;
}

.ReplyTooltipText {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}

.ReplyHeadingInfoIcon {
  color: $fbGrey__standard;
}

.ReplyForm__subText {
  font-style: italic;
}

.ReviewContent {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
}

.ReviewContent__left {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ReviewContent__createdDate {
  margin-top: 5px;
  margin-bottom: 8px;
}

.ReviewContent__RatingContainer {
  padding-top: 20px;
}

.SubRatingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.SubRatingRating {
  display: flex;
  align-items: center;
}

.SubRatingRatingTitle {
  text-transform: capitalize;
  color: $fbGrey__standard;
}

.SubRatingRatingValue {
  color: $fbGrey__standard;
  padding-left: 4px;
}

.ReplyForm {
  margin-top: 12px;
}

.InputContainer {
  width: 100%;
}

.ReplySubmitBtnContainer {
  display: flex;
  flex-direction: column;
  @media (min-width: $md) {
    flex-direction: row;
  }
}

.CancelBtn {
  margin-bottom: 6px !important;
  padding: 6px 28px !important;
  @media (min-width: $md) {
    padding: 16px 32px !important;
    margin-bottom: 0 !important;
    margin-right: 6px !important;
    width: auto;
    min-width: 170px;
  }
}

.SubmitBtn {
  margin-top: 6px !important;
  padding: 6px 28px !important;
  @media (min-width: $md) {
    padding: 16px 32px !important;
    margin-top: 0 !important;
    margin-left: 6px !important;
    flex: 1;
  }
}

.SubmitBtn__loading {
  color: $fbGrey__standard;

  svg {
    margin: 0 !important;
  }
}

.FakeGreenLinkBtn {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  display: inline;
  background: transparent;
  font: $font__bodyRegular;
  color: $fbGreen__standard;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    color: $fbGreen__dark;
  }
}

.APIErrorContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
}

.ErrorMessageContainer {
  min-height: 24px;
  padding: 4px 8px;
  font: $font__bodyRegular;
  font-size: 12px;
  line-height: 140%;
  color: $fbRed__standard !important;
}

.DeleteReplyBtn {
  float: right;
  margin: 0;
}

.MissingContent {
  font-style: italic;
  color: $fbGrey__light;
}
