@import '../../../stylesheets/variables.scss';

.CreateZoneModal__paper {
  margin: 20px !important;
  position: relative;
  min-height: 500px;
  max-width: 90vw;

  @media (min-width: $sm) {
    min-width: 400px;
  }

  @media (min-width: $md) {
    min-width: 600px;
    margin: inherit;
  }
}

.CreateZoneModal__root {
  z-index: 1500 !important;
}

.CreateZoneModal__title {
  padding: 16px 32px 16px 16px;

  @media (min-width: $md) {
    padding: 24px 48px 24px 24px;
  }
}

.CloseBtn {
  position: absolute;
  top: 8px;
  right: 8px;
}

.InputRow {
  flex-wrap: wrap;
  width: 100%;
  display: flex;

  @media (min-width: $md) {
    flex-wrap: nowrap;
  }
}

.Input__halfWidth {
  width: 100%;

  @media (min-width: $md) {
    flex: 1;

    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }
}

.SubmitBtn__container {
  display: flex;
  justify-content: flex-end;
}

.SubmitBtn {
  margin-right: 0;
}

.ErrorContainer {
  display: flex;
  justify-content: center;
}

.ButtonLoadingSpinner {
  width: 24px !important;
  height: auto !important;
}

.ZoneModeSelectionContainer {
  margin-top: 60px;
}

.ZoneModeSelectionButton {
  margin-top: 54px !important;
  margin-bottom: 54px !important;
}

.TemplateSelectionBreadcrumbs {
  display: flex;
  align-items: center;
}

.ChevronIcon {
  color: $fbGreen__light;
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 0;
  font-size: 30px;
}

.TypographyButton {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  &:focus,
  &:hover {
    border: none;
    outline: none;
  }
  &:hover {
    opacity: 0.8;
  }
}

.TypographyButton__text {
  margin: 0;
}
