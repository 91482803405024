@import '../../stylesheets/variables.scss';

p {
  font: $font__bodyRegular;
  color: $fbGrey__dark;
}

/*
Style sheet written in BEM
Block = .block
Element = .block__element
Modifier = .block__element_modifier
*/

.AddOrEditMultipleProductsContainer__stepperBtnsContainer {
  display: flex;
  padding-bottom: 30px;
  padding-top: 20px;
  flex-wrap: wrap;
  justify-content: space-around;
  position: fixed;
  background: white;
  box-shadow: 0 -2px 4px -1px rgba(0, 0, 0, 0.2);
  bottom: 0;
  left: 0;
  height: 30px;
  width: 100%;
}

.AddOrEditMultipleProductsContainer__Spinner {
  svg {
    margin: 0 !important;
  }
}

.AddOrEditMultipleProductsContainer__stepperBtn {
  margin: 0;
  min-width: 160px;
  .icon {
    margin-right: 8px;
  }
  &:disabled {
    color: $fbGrey__standard;
    background: $fbGrey__lightest;
    border: 1px solid $fbGrey__light;
    box-shadow: none;
    cursor: not-allowed;
    pointer-events: initial;
    svg {
      color: $fbGrey__standard;
    }
    &:hover {
      color: $fbGrey__standard;
      svg {
        color: $fbGrey__standard;
      }

      background: $fbGrey__lightest;
      border: 1px solid $fbGrey__light;
      box-shadow: none;
      cursor: not-allowed;
    }
  }
}

.AddOrEditMultipleProductsContainer__stepper_label {
  font: $font__bodyRegular;
}

.AddOrEditMultipleProductsContainer__stepper_paper {
  background: transparent;
}

.AddOrEditMultipleProductsContainer__stepper_orange {
  background: $fbOrange__standard;
}

.WarningLink {
  color: $fbYellow__standard;
  text-decoration: underline;

  &:hover {
    color: $fbYellow__dark;
  }
}

.StepIcon__active {
  color: $fbGreen__light !important;
}

.StepIcon__completed {
  color: $fbGreen__standard !important;
}
