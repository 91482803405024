@import '../../stylesheets/variables.scss';

.ChipText {
  color: $fbYellow__dark;
}

.Emoji {
  padding-left: 4px;
  padding-right: 4px;
}
