@import '../../stylesheets/variables.scss';

.PageContainer {
  padding-bottom: 80px;
}

.LayoutContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @media (min-width: $lg) {
    flex-direction: row;
  }
}

.ActionBar {
  display: flex;
  flex: 1;
  justify-content: center;

  @media (min-width: $lg) {
    justify-content: flex-end;
  }
}

.ActionBar__buttonsContainer {
  display: flex;
  flex-direction: column;

  @media (min-width: $lg) {
    flex-direction: row;
  }
}

.ActionBar__button {
  height: 41px;
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  margin: 8px 8px 8px 0;
}

.Order__content {
  max-width: $maxPageWidth;
  margin: auto;
  padding: 20px 0 20px 12px;

  @media (min-width: $md) {
    padding: 20px 0 20px 32px;
  }

  @media (min-width: $lg) {
    padding: 20px 32px;
  }
}

.OrderWidgetPaperWrapper {
  @media (min-width: $md) {
    margin-right: 0;
  }
}

.Order__detailsHeaderRow {
  display: flex;
  justify-content: space-between;
  max-width: 888px;
  margin-bottom: 16px;
}

.Order__detailsHeaderRowLeft {
  display: flex;
  flex-wrap: wrap;
}

.Order__orderNumberHeader {
  margin-right: 14px;
  margin-top: 8px;
}

.DeliverTo {
  display: block;
}

.VenueName {
  word-wrap: break-word;
}

.DeliveryDetailsSummary {
  display: block;
}

.DeliveryInstructions {
  display: block;
}

.OrderDetails__row {
  padding-bottom: 8px;
}

.DeliveryDetails__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

.DeliveryDetails__title {
  flex: 0.24;
  line-height: 140%;
}

.DeliveryDetails__value {
  flex: 0.75;
  justify-self: flex-start;
  line-height: 140%;

  .email {
    margin-left: 30px;
  }
}

.Order__deliveryInstructions,
.Order__cancellationNotes {
  display: flex;
  flex-flow: column;
  height: 70%;
  padding-left: 12px;
  padding-right: 12px;

  @media (min-width: $md) {
    padding: 0 18px 0 18px;
  }

  @media (min-width: $lg) {
    padding: 0 18px 0 18px;
  }
}

.ProductDetailsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.ProductsTable {
  width: 100%;

  td,
  th {
    width: 120px;
    padding-right: 8px;
    padding-left: 4px;

    &.OrderProductId {
      width: 50px;
    }

    &.SKU {
      width: 110px;
    }

    &.ProductName {
      width: 345px;
      flex-wrap: wrap;
    }

    &.Ordered {
      width: 100px;
    }

    &.Supplied {
      width: 100px;
    }

    &.Quantity {
      width: 60px;
    }

    &.GST {
      width: 100px;
    }

    &.Value {
      width: 80px;
    }
  }
}

.Flame_Icon_Orange {
  color: $fbOrange__standard;
  width: 18px;
  padding-right: 4px;
}

.UpdatedItemRed {
  color: $fbRed__standard;
  font-weight: bold;
}

.ProcessOrderPanel {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  max-width: $maxPageWidth;
  margin-left: 12px;
  padding: 12px;
}

.CompletedOrderPanel {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  max-width: $maxPageWidth;
  padding: 12px;
  margin-top: 36px;
  margin-bottom: 16px;
}

.OriginalOrder {
  color: $fbGrey__light;
  margin-left: 12px;
  margin-right: 12px;
}

.SuppliedValueContainer {
  margin-top: 12px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.SuppliedValue {
  display: flex;
  align-items: center;
}

.PercentageChange {
  color: $fbRed__standard;
  margin-left: 12px;
  margin-bottom: 8px;
}

.SuppliedOrderValue {
  color: $fbGrey__dark;
  font-weight: bold;
  font-size: 26px;
}

.SuppliedOrderLabel {
  margin-right: 12px;
}

.ErrorWrapper {
  margin: auto;
  max-width: 94%;
  width: 400px;
}

.AddSubstitutionContainer {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CancelOrderContainer {
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.OrderPDF__Btn {
  .icon {
    margin-right: 8px;
    color: $fbGrey__light;
    line-height: 20px;
  }

  white-space: nowrap;
}

.downloadIcon {
  color: $fbGrey__light;
  padding-right: 8px;
}

.Tooltip {
  font-size: 15px;

  .Arrow {
    position: absolute;
    font-size: 6;

    &::before {
      content: '';
      margin: auto;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 1em 1em 0 1em;
      border-color: $fbBlue__dark transparent transparent transparent;
    }

    bottom: 0;
    left: calc(50% - 1em);
    width: 2em;
    height: 1em;
  }
}

.OriginalOrderTotal {
  display: flex;
  justify-content: space-between;
}

.ProcessedStatusChip {
  padding-right: 12px;
}

.SubstitutionChip {
  margin-left: -1px;
  border-radius: 4px;
  margin-top: -5px;
  .StatusChips__susses {
    background-color: none;
  }

  span {
    font-weight: bold;
  }

  &.Allowed {
    color: $fbGreen__dark;
    background: $fbGreen__illustration;
  }

  &.NotAllowed {
    color: $fbRed__dark;
    background: $fbRed_illustration;
  }
}

.Link {
  all: unset;
  cursor: pointer;

  &.Active {
    text-decoration: underline;
    color: $fbGreen__standard;

    &:hover {
      color: $fbGreen__dark;
    }
  }

  &.Grey {
    text-decoration: line-through;
    color: $fbGrey__standard;

    &:hover {
      color: $fbGrey__dark;
    }
  }
}

.Strikethrough {
  text-decoration: line-through !important;
}

.EditableQuantityCell {
  display: flex;
}

.ProcessOrderButton {
  white-space: nowrap;

  svg {
    margin: 0;
  }

  .icon {
    margin-right: 5px;
    line-height: 20px;
  }
}

.Substitution {
  &.pulse {
    transform: scale(1);
    animation: pulse 0.8s infinite;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }

  70% {
    transform: scale(0.98);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.PriceContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

  div {
    width: 100%;
    text-align: left;
  }
}

.PricePercentageChange {
  font: $font__bodyRegular;
  color: $fbRed__standard;
}

.MenuLink {
  font: $font__bodyRegular;
  font-size: 18px;
  color: $fbGrey__standard;
  border-radius: $borderRadius__button;

  &:focus {
    color: $fbGrey__standard;
    text-decoration: none;
    outline: none;
  }

  &:hover,
  &:active {
    text-decoration: none;
  }
}

.WarningNotificationMessageSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.WarningNotificationMessageSection__title {
  margin: 0;
  padding-right: 16px;
}

.UpdatedDeliveryDetailsBtn {
  background: $fbYellow__light !important;
  color: $fbGrey__dark !important;
  border: 1px solid $fbYellow__dark !important;

  svg {
    color: $fbGrey__dark !important;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.DeleteButton {
  display: flex;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
    background-color: transparent;
  }
}

.DeleteIcon {
  color: red !important;
  width: 100%;
  background-color: transparent;
  outline: none;
  border-radius: 50%;

  &:hover {
    box-shadow: $drawerBoxShadow;
  }
}

.ErrorText {
  font-size: 14px;
  color: $fbGrey__standard;
  color: $fbRed__standard;
}

.SubtitutionTextColor {
  font-size: 14px;
  color: $fbGrey__standard;
}

.ProductNameContainer {
  &.Tall {
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }
}

.ActionButtonContainer {
  display: block;
}

.ActionButton {
  padding-left: 10px !important;
  padding-right: 10px !important;

  &:focus {
    border: 1px solid $fbGrey__standard !important;
  }
}

.ChevronButton {
  display: none;

  @media (min-width: $md) {
    display: block;
  }
}

.RadioLabel__root {
  border-radius: $borderRadius__button;
  margin: 0;
  padding: 5px;
  padding-right: 25px;
}

.Dropdown__content {
  display: flex;
  flex-direction: column;
  margin-right: -24px;
}

.FormControlLabel__label {
  font: $font__bodyRegular;
  font-size: 15px;
  color: $fbGrey__standard;
  cursor: pointer;
  padding-right: 20px;
}

.LiquorStatusChip {
  margin-left: -4px;
  margin-top: -2px;
}

.DeliveryDetailsContainer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.DeliveryDetailsWrapper {
  margin-top: 10px;
  margin-bottom: 10px;

  &.DeliverTo {
    flex-grow: 0.7;
  }

  &.DeliveryDetails {
    flex-grow: 1;
  }

  @media (min-width: $md) {
    margin-top: 0.01px;
    margin-bottom: 0.01px;
  }
}

.OrderType {
  margin-top: 8px;
  margin-left: 5px;
}

.NegativeMargin {
  margin-top: -20px;
  padding-left: 40px;

  @media (min-width: $md) {
    padding-left: 56px;
  }
}
