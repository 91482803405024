@import '../../stylesheets/variables.scss';

.CloseBtn {
  position: absolute;
  top: 8px;
  right: 8px;
}

.DownloadProductsDialog__root {
  z-index: 1500 !important;
}

.DownloadProductsDialog__paper {
  margin: 20px !important;
  position: relative;

  @media (min-width: $md) {
    margin: inherit;
    min-width: 600px;
  }
}

.DownloadProductsDialog__title {
  padding: 16px 32px 16px 16px;

  @media (min-width: $md) {
    padding: 24px 48px 24px 18px;
  }
}

.DownloadProductsDialog__content {
  transition: all $navigationTransitionTime;
  min-height: 200px;
  padding: 0 16px 16px 16px;

  @media (min-width: $md) {
    padding: 0 24px 24px 24px;
  }

  &.expand {
    min-height: 700px;
  }
}

.DownloadBtnContainer {
  width: 100%;
  padding-top: 16px;
  display: flex;
  justify-content: center;
}

.ErrorContainer {
  display: flex;
  justify-content: center;
}

.SubmitLoadingSpinner {
  line-height: 24px;
  width: 22px !important;
  height: 22px !important;
  svg {
    color: $fbGrey__dark !important;
    margin: 0 !important;
  }
}

.DownloadBtn {
  &:hover {
    svg {
      color: white !important;
    }
  }
}
