@import '../../stylesheets/variables.scss';

.LossLeaderContainer {
  padding-bottom: 80px;
  overflow: hidden;

  @media (min-width: $md) {
    overflow: unset;
  }
}

.LossLeaderSubHeader {
  margin-left: 12px;
  color: $fbGrey__standard;
}

.LossLeaderWrapper {
  max-width: 1200px;
  height: 100vh;
  margin: 10px auto;
}

.LossLeaderComponentsWrapper {
  display: flex;
  flex-direction: column;

  @media (min-width: $md) {
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: row;
  }
}
