@import '../../stylesheets/variables.scss';

.OrderStatusChipContainer {
  display: inline-block;
  margin-top: 8px;
  margin-bottom: 8px;
}

.OrderTypeChip {
  text-transform: capitalize;
  border-radius: $borderRadius__button;
  display: flex;
  align-items: center;
  font: $font__bodyBold;
  font-size: 16px;
  line-height: 145%;
  letter-spacing: 0.05em;
  padding: 4px 8px 4px 8px;
  background: $fbGreen__lightest;
  color: $fbGreen__dark;

  &.topup {
    background: $fbBlue__lightest;
    color: $fbBlue__dark;
  }

  &.standing {
    background: $fbBrown__lightest;
    color: $fbBrown__dark;
  }
}
