@import '../../stylesheets/variables.scss';

.CustomPriceDialog__paper {
  margin: 20px !important;
  position: relative;

  @media (min-width: $md) {
    margin: inherit;
    min-width: 700px;
  }
}

.CustomPriceDialog__root {
  z-index: 1500 !important;
}

.CustomPriceDialog__title {
  padding: 16px 32px 16px 16px;

  @media (min-width: $md) {
    padding: 24px 48px 24px 24px;
  }
}

.CustomPrice {
  max-width: 100%;
}

.CloseBtn {
  position: absolute;
  top: 8px;
  right: 8px;
}

.CustomPriceDialog__content {
  padding: 0 16px 16px 16px;

  @media (min-width: $md) {
    padding: 0 24px 24px 24px;
  }
}
