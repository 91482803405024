@import '../../stylesheets/variables.scss';

.PageContainer {
  padding-bottom: 80px;
}

.BreadcrumbsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 12px;
}

.ActionBar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ActionBar__buttonsContainer {
  display: flex;
  flex-wrap: wrap;
}

.ActionBar__button {
  height: 41px;
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  margin: 8px 8px 8px 0;
}

.DropdownIcon {
  color: $fbGrey__light;
  margin-left: 4px;
}

.MenuLink {
  font: $font__bodyRegular;
  font-size: 18px;
  color: $fbGrey__standard;
  border-radius: $borderRadius__button;

  &:focus {
    color: $fbGrey__standard;
    text-decoration: none;
    outline: none;
  }

  &:hover,
  &:active {
    text-decoration: none;
  }
}

.ErrorWrapper {
  margin: auto;
  max-width: 94%;
  width: 400px;
}

.TableContainer {
  padding: 20px;
}

.ProductsTable {
  td,
  th {
    width: 150px;
    padding-right: 4px;
    padding-left: 8px;

    &.SKU {
      width: 76px;
    }

    &.Image {
      width: 120px;
    }

    &.ProductName {
      width: 220px;
    }

    &.WTF {
      width: 108px;
    }

    &.Status {
      width: 150px;
      position: relative;
    }

    &.Price {
      width: 160px;
      justify-content: flex-end;
    }

    &.PriceUnit {
      width: 130px;
    }

    &.Portions {
      width: 130px;
    }

    &.Date {
      width: 150px;
    }

    &.ID {
      width: 75px;
    }

    &.IsBestBuy {
      width: 100px;
    }

    &.Actions {
      width: 150px;
    }
  }

  td {
    &.Price {
      margin-left: -16px;
      padding-right: 16px;
    }
  }

  th {
    &.Status {
      :first-child {
        justify-content: center;
      }
    }
  }
}

.WTFCellTitle {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;

  .Icon {
    font-size: 1.2rem;
  }
}

.Tooltip {
  font-size: 15px;

  .Arrow {
    position: absolute;
    font-size: 6;

    &::before {
      content: '';
      margin: auto;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 1em 1em 0 1em;
      border-color: $fbBlue__dark transparent transparent transparent;
    }

    bottom: 0;
    left: calc(50% - 1em);
    width: 2em;
    height: 1em;
  }
}

.NoResultsContainer {
  text-align: center;
  margin: auto;
  margin: 16px;
  padding: 12px;

  @media (min-width: $sm) {
    margin: 22px 12px 22px 12px;
    padding: 25px;
  }
}

.NoResultsActions {
  display: flex;
  justify-content: center;
}

.TypographySection {
  padding-top: 12px;
  padding-bottom: 12px;
}

.TypographyBody {
  margin-bottom: 8px;
}

.StatusChip__noMargin {
  margin: 0;
}

.ViewProductBtn {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProductImageContainer {
  padding: 0;
  margin: 0;
  width: 64px;
  height: 52px;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.ProductImage {
  max-width: 50px;
  max-height: 100%;
  background: none;
}

.SearchBar {
  @media (min-width: $sm) {
    width: 220px;
  }
}
