@import '../../stylesheets/variables.scss';

.DeliveryZones {
  min-width: 100%;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  // $mobileNavHeight;
  min-height: calc(100vh - 48px);
  max-height: calc(100vh - 48px);

  @media (min-width: $lg) {
    // $combinedAuthenticatedNavHeight
    min-height: calc(100vh - 108px);
    max-height: calc(100vh - 108px);
  }
}

.DeliveryZones__content {
  flex: 1;
}

.DeliveryZones__NavigationPanel {
  min-height: 100%;
  max-width: 260px;
  background-color: white;
  box-shadow: $elevatedShadow;
  z-index: 3;
  padding: 16px;
  @media (min-width: $sm) {
    max-width: 360px;
  }
  @media (min-width: $md) {
    padding: 32px;
  }
}

.MobileMenuDrawerPaper {
  top: $mobileNavHeight !important;

  @media (min-width: $lg) {
    top: $combinedAuthenticatedNavHeight !important;
  }
}

.MobileMenuDrawerBackdrop {
  top: $mobileNavHeight !important;

  @media (min-width: $lg) {
    top: $combinedAuthenticatedNavHeight !important;
  }
  div {
    @media (min-width: $lg) {
      top: $combinedAuthenticatedNavHeight !important;
    }
  }
}

.DeliveryZones__PageHeader {
  display: flex;
  background-color: white;
  justify-content: space-between;
  padding: 32px 24px;
  align-items: center;
}

.APIError {
  margin-left: 0;
  margin-right: 0;
  width: auto;
  max-width: unset;
}

.DeliveryZonesInfoPaper__Container {
  margin: auto;
}

.DeliveryZonesInfoPaper {
  text-align: center;
  box-shadow: $elevatedShadow;
  max-width: 90%;
  margin: auto;

  @media (min-width: $md) {
    max-width: 480px;
  }

  @media (min-width: $lg) {
    max-width: 540px;
  }
}

.DeliveryZonesInfoPaper__Logo {
  background-image: url('../../images/deliveryZonesLogo.png');
  background-position: center;
  background-size: contain;
  height: 146px;
  width: 146px;
  margin: 16px auto 28px auto;
}

.DeliveryZonesInfoPaper__Title {
  margin-bottom: 20px;
}

.DeliveryZonesInfoPaper__Actions {
  text-align: center;
  margin-top: 20px;

  .CreateZoneBtn {
    margin-left: auto;
    margin-right: auto;
  }
}

.SelectedDeliveryZone__Name {
  margin-bottom: 0;
}

.NavigationPanel__heading {
  display: flex;
  align-items: center;

  .HeadingText {
    margin-bottom: 0;
  }
}

.HeadingEmoji {
  font-size: 24px;
}

.ActionBar {
  margin-bottom: 4px;
  border-bottom: 1px solid $fbGrey__light;
  display: flex;

  .CreateZoneBtn {
    margin-bottom: 20px;
    margin-left: 0;
  }

  .BySuburbBtn {
    margin-bottom: 20px;
  }
}

.DeliveryDetails__headerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DeliveryZones__SectionContainer {
  display: flex;
}

.DeliveryZones__Section {
  flex: 1;
  margin: 24px;
}

.EditButton {
  margin: 0;
}

.NavigationPanel__ZoneIndex {
  height: 99%;
  overflow-y: scroll;
  margin-left: -32px;
  margin-right: -32px;
  padding-left: 32px;
  padding-right: 32px;
}

.DeliveryZoneIndexCardsContainer {
  margin-top: 16px;
  padding-bottom: 200px;
}

.ContentLoaderContainer {
  &:first-child {
    margin-top: 16px;
  }

  margin-bottom: 12px;
  svg {
    width: 100%;
    height: 122px;
  }
}
