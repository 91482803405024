@import '../../stylesheets/variables.scss';

.LoginPageContainer {
  // using $mobileNavHeight
  min-height: calc(100vh - 112px);
  padding: 32px 8px;
  display: flex;
  justify-content: center;
  background: $fbGrey__dark;

  @media (min-width: $md) {
    padding: 32px;
  }

  @media (min-width: $lg) {
    // using $desktopNavHeight
    min-height: calc(100vh - 128px);
  }
}
