@import '../../../stylesheets/variables.scss';

.AddSupplierOrderIdDialogRoot {
  z-index: 1500 !important;
}

.AddSupplierOrderIdDialogPaper {
  margin: 20px !important;
  min-width: 40vw;
  border: 2px solid $fbGrey__lightest;
  border-radius: $borderRadius__modal;
  min-height: 300px;
  padding: 8px;
  align-items: center;
  overflow-x: hidden;

  @media (min-width: $md) {
    min-width: 300px;
    max-width: 350px;
  }
}

.AddSupplierOrderIdDialog__closeBtn {
  position: absolute;
  right: 1px;
  top: 1px;
}

.FormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FormRow {
  flex: 1;
  min-width: 100%;
}

.SubmitBtn {
  width: 100px !important;
}

.DialogHeader {
  text-align: center;
}

.BodyText {
  text-align: left;
  max-width: 90%;
  padding: 16px;
  padding-top: 0;
}

.FillableFieldContainer {
  width: 90%;
}
