@import '../../../stylesheets/variables.scss';

.LossLeaderVenuesDialogPaper {
  margin: 20px !important;
  border: 2px solid $fbGrey__lightest;
  border-radius: $borderRadius__modal;
  min-height: 400px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  overflow-y: visible;

  @media (min-width: $md) {
    min-width: 500px;
    max-width: 500px;
    min-height: 365px;
  }
}

.LossLeaderVenuesDialogHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 30px;
}

.LossLeaderVenuesDialogContent {
  margin-top: 20px;
  margin-bottom: 30px;
  width: 100%;

  div {
    color: $fbGrey__standard;
  }
}

.LossLeaderVenuesDialogContentText {
  margin-bottom: 20px;
}

.LossLeaderVenuesDialogSelectedField {
  margin-bottom: 10px;
}

.LossLeaderVenuesDialogAction {
  margin-bottom: 10px;
}

.LossLeaderVenuesModalButton {
  position: absolute;
  right: 10px;
}

.SubmitLoadingSpinner {
  color: $fbGrey__light;

  svg {
    margin: 0 !important;
  }
}

.ErrorMessageContainer {
  min-height: 24px;
  padding: 4px 8px;
  font: $font__bodyRegular;
  font-size: 12px;
  line-height: 140%;
  color: $fbRed__standard !important;
}
