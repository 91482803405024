@import '../../stylesheets/variables.scss';

body {
  background-color: $fbGrey__lightest !important;
  overscroll-behavior-y: none;

  ::selection {
    background: $fbGreen__lightest;
  }
}

.App {
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  background: $fbGrey__lightest;
}

.PageWrapper {
  &.withNavigation {
    padding-top: $mobileNavHeight;

    @media (min-width: $lg) {
      padding-top: $desktopAppBarHeight;
    }

    &.asAuthenticated {
      @media (min-width: $lg) {
        padding-top: $combinedAuthenticatedNavHeight;
      }
    }
  }
}

.UnauthenticatedWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.UnauthenticatedError {
  width: 300px;
  max-width: 90%;
}

.UnauthenticatedError__content {
  margin-top: 12px;
}
