@import '../../stylesheets/variables.scss';

.PageContainer {
  padding-bottom: 80px;
}

.SubheadingContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 12px;
}

.DownloadActionBtn__purchaseOrder {
  min-width: 231px;
}

.DownloadActionBtn__deliveryDocket {
  min-width: 193px;
}

.DownloadActionBtn__invoice {
  min-width: 146px;
}

.DownloadActionBtn__orderCSV {
  min-width: 146px;
}

.DownloadActionsContainer {
  overflow-x: scroll;
  white-space: nowrap;
  display: flex;
  margin-left: -12px;
  margin-right: -12px;

  @media (min-width: $md) {
    overflow-x: unset;
    white-space: unset;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
}

.ActionBar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.SearchBarContainer {
  padding-top: 8px;
  width: 450px;
}

.ActionBar__buttonsContainer {
  display: flex;
  padding-top: 8px;
}

.ActionBar__button {
  height: 44px;
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  margin: 8px 16px 8px 0;
}

.ActionButton__icon {
  color: $fbGrey__light;
  margin-right: 8px;
}

.BulkActionButton__icon {
  color: $fbGrey__dark;
  margin-right: 8px;
}

.Tooltip {
  font-size: 14px;
}

.downloadIcon {
  padding-right: 8px;
}

.TableContainer {
  padding: 20px;
}

.NoResultsContainer {
  text-align: center;
  margin: auto;
  margin: 16px;
  padding: 12px;

  @media (min-width: $sm) {
    margin: 22px 12px 22px 12px;
    padding: 25px;
  }
}

.TypographySection {
  padding-top: 12px;
  padding-bottom: 12px;
}

.TypographyBody {
  margin-bottom: 8px;
}

.OrdersTable {
  td,
  th {
    width: 100px;
    padding-right: 4px;
    padding-left: 8px;

    &.OrderID {
      width: 120px;
    }

    &.SupplierOrderId {
      width: 140px;
    }

    &.OrderType {
      width: 120px;
    }

    &.Placed {
      width: 180px;
    }

    &.Delivery {
      width: 120px;
    }

    &.CompanyName {
      width: 200px;
    }

    &.CustomerName {
      width: 180px;
    }

    &.CustomerAlert {
      width: 212px;
    }

    &.Status {
      width: 140px;
    }

    &.OrderValue {
      width: 120px;
    }
  }
}

.VenueColumnChild {
  display: flex;
  overflow: hidden;
}

.VenueName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 12px;
}

.TableBtn {
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
}

.RowAction {
  margin: 8px;
  min-width: 176px;
}

.SubmitBtn__loading {
  color: $fbGrey__dark;
  line-height: 12px;
  margin-right: 8px;

  svg {
    margin: 0 !important;
  }
}

.MenuLink {
  font: $font__bodyRegular;
  font-size: 18px;
  color: $fbGrey__standard;
  border-radius: $borderRadius__button;

  &:focus {
    color: $fbGrey__standard;
    text-decoration: none;
    outline: none;
  }

  &:hover,
  &:active {
    text-decoration: none;
  }
}

.ErrorWrapper {
  margin: auto;
  max-width: 94%;
  width: 400px;
}

.TooltipPadding {
  padding: 12px;
}

.BoldText {
  font: $font__bodyBold;
}

.NumberSelectedOrdersBar {
  color: $fbGreen__dark;
  max-width: 1060px;
  margin: auto;
  background: $fbGreen__lightest;
  border-radius: $borderRadius__button;
  border: 1px solid $fbGreen__light;
  padding: 8px;
  margin-bottom: 8px;
  text-align: center;
  font: $font__bodyBold;
}

.NumberSelectedOrdersBar__badge {
  margin-top: -4px;
}

.NumberSelectedOrdersBar__space {
  width: 100%;
  height: 42px;
}

.BulkActionDialogMessage {
  text-align: center;
  margin-bottom: 16px;
}

.StandOutBtn {
  background: $fbYellow__light !important;
  color: $fbGrey__dark !important;
  border: 1px solid $fbYellow__dark !important;

  svg {
    color: $fbGrey__dark !important;
  }
}
