html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.Sidebar {
  float: left;
  height: 100vh;
  width: 15%;
  background-color: #f9f9f9;
  box-shadow: 2px 2px 13px 0px #d0d0d0;
}

.Sidebar .menuItem {
  padding: 5px 0 5px 15px;
  border-bottom: 1px lightgrey solid;
  cursor: pointer;
}

.menuItem.divider {
  padding: 0;
  height: 4px;
  background-color: lightgrey;
}

.Sidebar .menuItem:hover {
  background-color: lightgrey;
}

.AppContent {
  float: left;
  padding: 10px 0 0 15px;
  width: 83%;
}

.Login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 86vh;
}

.baseDetails {
  margin: 20px;
  padding-bottom: 50px;
}
.baseDetails .banner {
  color: white;
  background-color: #254325;
  height: 50px;
  text-align: center;
  font: 500 15px 'Montserrat';
  line-height: 50px;
  margin-bottom: 60px;
}
.baseDetails .orderDetails,
.baseDetails .deliveryDetails {
  float: left;
  margin-left: 60px;
}
.baseDetails .orderDetails div,
.baseDetails .deliveryDetails div {
  font: 400 14px 'Montserrat';
  line-height: 23px;
}
.baseDetails .orderDetails div:nth-child(1),
.baseDetails .deliveryDetails div:nth-child(1) {
  font: 600 14px 'Montserrat';
  line-height: 23px;
  text-transform: uppercase;
}

.sellerInfoRow {
  clear: both;
  margin-left: 60px;
  padding-top: 35px;
  padding-bottom: 35px;
}
.sellerInfoRow img {
  float: left;
  max-height: 55px;
  max-width: 55px;
}
.sellerInfoRow .info {
  float: left;
  margin-left: 35px;
  font: 400 14px 'Montserrat';
  line-height: 20px;
}
.sellerInfoRow .info .sellerName {
  color: #85b329;
  font: 600 14px 'Montserrat';
}
.productRow {
  clear: both;
  margin-left: 60px;
  margin-right: 60px;
  margin-top: 15px;
  font: 400 14px 'Montserrat';
  padding-top: 10px;
}
.productRow .name {
  float: left;
}
.productRow .portion,
.productRow .qty,
.productRow .total,
.productRow .qtyProvided {
  float: right;
  margin-left: 50px;
  text-align: right;
}
.productRow .portion {
  width: 10%;
}
.productRow .qty {
  width: 10%;
}
.productRow .total {
  width: 10%;
}
.productRow .qtyProvided {
  width: 10%;
}
.productRow.headerRow {
  font: 600 14px 'Montserrat';
}
.summaryRow {
  clear: both;
  margin: 20px 60px 0 60px;
  padding-top: 10px;
}
.summaryRow .summaryName {
  float: left;
  font: 600 14px 'Montserrat';
}
.summaryRow .summaryValue {
  float: right;
  font: 400 14px 'Montserrat';
}
.summaryRow .summaryName.gt,
.summaryRow .summaryValue.gt {
  font: 600 19px 'Montserrat';
  color: #84b428;
  padding-bottom: 60px;
}

.dividerLong {
  clear: both;
  margin-top: 50px;
  width: 94%;
  margin-left: 3%;
  border: 0;
  height: 1px;
  background-image: -webkit-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -moz-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -ms-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: linear-gradient(to left, #f0f0f0, #8c8b8b, #f0f0f0);
}
