@import '../../stylesheets/variables.scss';

/*
Style sheet written in BEM
Block = .block
Element = .block__element
Modifier = .block__element_modifier
*/

.MultipleProductsContainer__instructionsContainer {
  margin: 5px auto;
  overflow: scroll;
  height: 200px;
  text-align: left;
  transition: all 250ms;
  background: white;
  border-radius: 10px;
  padding: 16px;
  margin-top: 20px;
  width: 80%;

  p {
    padding: 2px;
  }
}

.MultipleProductsContainer__instructionsPaper {
  padding: 20px;
  text-align: center;
  margin: auto;

  @media (min-width: $md) {
    width: 55%;
  }

  p {
    padding: 2px;
  }
}

.MultipleProductsContainer__CSVFormatErrorContainer {
  margin: auto;
  padding: 20px;

  @media (min-width: $md) {
    width: 55%;
  }

  p {
    padding: 2px;
  }
}

.MultipleProductsContainer__CSVInputContainer {
  position: relative;
  margin: auto;
  min-height: 100px;
  padding: 4% 0 4% 0;
  text-align: center;
}

.MultipleProductsContainer__CSVUploadSpinner {
  position: absolute;
  width: 100%;
  top: -11%;
}

.MultipleProductsContainer__CSVInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;

  /* This makes the button huge so that it can be clicked on */
  font-size: 100px;
  outline: none;

  &:focus,
  &:active {
    outline: none;
  }
}

.MultipleProductsContainer__CSVInputWrapper {
  margin: 12px;
  font: $font__bodyBold;
  letter-spacing: 0.03em;
  line-height: 140.62%;
  font-size: 16.5px;
  padding: 10px 16px;
  background: $fbGreen__standard;
  border: 1px solid $fbGreen__standard;
  border-radius: $borderRadius__button;
  color: white;
  justify-content: center;
  align-items: center;
  text-transform: none !important;
  font-variant: normal !important;
  transition: all 400ms;
  outline: none !important;
  position: relative;
  width: 40%;
  overflow: hidden;
  text-align: center;
  height: 48px;
  min-width: 48px;
  min-height: auto;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  vertical-align: middle;
  text-decoration: none;

  input {
    color: transparent;
  }

  &:hover {
    outline: none !important;
    background: $fbGreen__dark;
    border: 1px solid $fbGreen__standard;
    box-shadow: $darkBoxShadow;

    input {
      color: transparent;
    }
  }

  &:active {
    box-shadow: $darkBoxShadow;
  }
}

.MultipleProductsContainer__csvInput_btn {
  width: 100%;
  height: 100%;
  font: $font__bodyRegular;
  font-size: 18px;
}

.MultipleProductsContainer__confirmationContainer {
  margin: 0 auto;
  padding: 20px;
}

.MultipleProductsContainer_ErrorBtns {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.MultipleProductsContainer__confirmationContainer_paper {
  max-width: 1200px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 300px;
}

.MultipleProductsContainer__summaryContainer {
  margin: 20px 0 60px 0;
}

.MultipleProductsContainer__productsTableContainer {
  margin: auto;
  padding: 10px 0;
  display: block;
  width: 100%;
}

// Modifiers:
.MultipleProductsContainer {
  font: $font__bodyBold;
  font-size: 14px;
  text-align: center;
  margin-bottom: 6px;
  &__text_foodBombGreen_bold {
    color: $fbGreen__standard;
  }
}

.MultipleProducts__cta_container {
  display: block;

  @media (min-width: $md) {
    margin: 0 auto;
    width: 90%;
    display: flex;
  }

  @media (min-width: $lg) {
    width: 60%;
  }
}

.MultipleProductsContainer__btn {
  margin: 0 auto;
  margin-top: 18px;
}

.MultipleProductsContainer__text_pointer {
  cursor: pointer;
}

.MultipleProductsContainer__text_underline {
  text-decoration: underline;
}

.MultipleProductsContainer__text_center_parent {
  text-align: center;
}

.MultipleProductsContainer__text_center_child {
  display: inline-block;
  padding: 20px 0 0 0;
}

.MultipleProductsContainer__instructionsContainer_hidden {
  height: 0;
  transition: all 250ms;
  padding: 0;
}

.MultipleProductsContainer__CSVFormatErrorContainer_text {
  color: $fbRed__dark;
  font: $font__headingMedium;
  font-size: 14px;
}

.MultipleProductsContainer__CSVFormatErrorMessageTitle {
  font: $font__headingSemiBold;
  color: $fbRed__dark;
  font-size: 16px;
  padding-bottom: 4px;
  text-align: center;
}

.MultipleProductsContainer__confirmationContainer__btn {
  border: 1px solid $fbOrange__standard;
  border-radius: 4px;
  color: $fbOrange__standard;
  font: $font__headingSemiBold;
  font-size: 14px;
  text-align: center;
  padding: 10px;

  .icon {
    margin-right: 8px;
  }

  &:hover {
    color: $fbOrange__dark;
    border: 1px solid $fbOrange__dark;
  }
}

.MultipleProductsContainer__ErrorBtns__DownloadErrorsBtn {
  border: 1px solid $fbOrange__standard;
  border-radius: 4px;
  color: $fbOrange__standard;
  font: $font__headingSemiBold;
  font-size: 14px;
  text-align: center;
  padding: 10px;

  .icon {
    margin-right: 8px;
  }

  &:hover {
    color: darken($fbOrange__standard, 10%);
    border: 1px solid darken($fbOrange__standard, 10%);
  }
}

.MultipleProductsContainer__dropzoneContainer_orange_boarder {
  border: 2px dashed $fbOrange__standard;
  min-height: 100px;
  //width: 80%;
  margin: 0 auto;
  padding: 25px;

  @media (min-width: $md) {
    overflow: hidden;
    width: 45%;
    margin-bottom: 20px;
  }
}

.MultipleProductsContainer__dropzoneContainerPagagraph {
  font: $font__headingMedium;
  font-size: 22px;
}

.MultipleProductsContainer__instructions_disc {
  list-style-type: disc;
  padding: 10px 0 0 30px;

  li {
    color: $fbGrey__dark;
    font: $font__bodyRegular;
    font-size: 16px;
    line-height: 140%;
  }
}

.MultipleProductsContainer__instructions_table_border {
  color: $fbGrey__dark;
  font: $font__bodyRegular;
  font-size: 16px;
  line-height: 140%;
}

.MultipleProductsContainer__instructions_table_border th {
  border: 1px solid black;
  padding: 5px 10px;
}

.MultipleProductsContainer__CSVInputContainer__semi_translucent {
  opacity: 0.4;
}

.MultipleProductsContainer__downloadProductsContainer {
  width: 30%;
  margin: auto;
}

.MultipleProductsContainer__CSVFormatErrorPosition {
  margin: auto;
  padding: 0 0 4px 0;

  @media (min-width: $md) {
    width: 55%;
  }

  p {
    padding: 2px;
  }
}

.MultipleProductsContainer__downloadProductsContainer_btn {
  margin: auto;
  .icon {
    margin-right: 8px;
  }
}

.MultipleProductsContainer__confirmationContainer_largeText {
  font: $font__headingMedium;
  font-size: 20px;
  color: #797979;
  margin-bottom: 5px;
}

.MultipleProductsContainer__CSVFormatErrorPosition_animation {
  animation-name: shake;
  animation-duration: 1300ms;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

@keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-3px, 0, 0);
    transform: translate3d(-3px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(3px, 0, 0);
    transform: translate3d(3px, 0, 0);
  }
}

.MultipleProductsContainer__Spinner {
  color: $fbGrey__standard;
  svg {
    margin: 0 !important;
  }
}

.MultipleProductsContainer__Spinner_white {
  color: white;
}

.OrderBreadcrumbsAndBtnsWrapper {
  width: 100% !important;
}

.FakeLinkBtn {
  border: none;
  outline: none;
  font: $font__bodyRegular;
  color: $fbGreen__standard;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
  display: inline-block;
  background: transparent;
  padding: 0;

  &:hover {
    color: $fbGreen__dark;
  }
}

.ReadMoreLinkContainer {
  padding-top: 20px;
}

.UploadSuccessMessage {
  margin-top: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.CustomPriceList__container {
  padding: 40px;
}

.CustomPriceList__heading {
  margin-bottom: 16px;
}

.ProductsWithCustomPriceList {
  list-style: inherit;
  margin-left: 20px;
}

.CustomPriceLi {
  padding-bottom: 4px;
}

.CustomPricesList {
  list-style: inherit;
  margin-left: 40px;
}

.EditCustomPriceBtn {
  border: none;
  outline: none;
  font: $font__bodyRegular;
  color: $fbGreen__standard;
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
  display: inline-block;
  background: transparent;
  padding: 0;
  margin-left: 4px;

  &:hover {
    color: $fbGreen__dark;
  }
  &.Grey {
    color: $fbGrey__standard;
    &:hover {
      color: $fbGrey__dark;
    }
  }
}
