@import '../../../stylesheets/variables.scss';

.SpecialsIcon {
  color: $fbOrange__standard;

  &.inline {
    margin-bottom: -4px;
    padding-right: 4px;
    padding-top: 4px;
  }

  &.forHeading {
    font-size: 1.1em;
  }

  &.asEmoji {
    height: 20px;
    width: 20px;
    padding: 0;
    margin: -2px 0 0 -4px;
  }
}
