@import '../../stylesheets/variables.scss';

.OrderStatusChipContainer {
  display: inline-block;
  margin-top: 8px;
  margin-bottom: 8px;
}

.OrderStatusChip {
  text-transform: capitalize;
  border-radius: $borderRadius__button;
  display: flex;
  align-items: center;
  font: $font__bodyRegular;
  font-size: 16px;
  line-height: 145%;
  letter-spacing: 0.05em;
  padding: 4px 8px 4px 8px;
  background: $fbYellow__lightest;
  color: $fbYellow__dark;

  &.completed {
    background: $fbGreen__lightest;
    color: $fbGreen__dark;
  }

  &.cancelled {
    background: $fbRed__lightest;
    color: $fbRed__dark;
  }
}
