@import '../../stylesheets/variables.scss';

.PageContainer {
  max-width: $maxPageWidth;
  margin: auto;
  padding-bottom: 120px;
}

.ProductFormAndDetailsContainer {
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 8px;
  padding-right: 8px;

  @media (min-width: $md) {
    padding-left: 16px;
    padding-right: 16px;
    flex-wrap: nowrap;
  }
}

.ProductFormContainer {
  width: 100%;
  flex: none;

  @media (min-width: $md) {
    flex: 0.66;
    width: auto;
  }
}

.ProductDetailsContainer {
  flex: auto;
  padding-left: 0;

  @media (min-width: $md) {
    flex: 0.33;
    padding-left: 16px;
  }
}

.ProductForm__submitting {
  cursor: progress;
}

.ProductForm__section {
  margin-top: 40px;
  position: relative;
}

.SectionCompleteIcon {
  transition: all $navigationTransitionTime;
  color: $fbGreen__light;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;

  &.hide {
    right: 32px;
    top: 32px;
    width: 0;
    height: 0;
  }
}

.SectionInvalidIcon {
  transition: all $navigationTransitionTime;
  color: $fbRed__standard;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;

  &.hide {
    right: 32px;
    top: 32px;
    width: 0;
    height: 0;
  }
}

.ProductFormSectionHeading {
  margin-bottom: 16px;

  .RequiredIcon {
    color: $fbRed__light;
    cursor: pointer;
  }
}

.ProductFormSectionDescription {
  margin-bottom: 28px;
}

.InputLabel {
  margin-left: 8px;
  font: $font__bodyRegular;
  font-size: 14px;
  line-height: 140%;
  color: $fbGrey__standard;

  &.error {
    color: $fbRed__standard !important;
  }
}

.ErrorMessageContainer {
  min-height: 24px;
  padding: 4px 8px;
  font: $font__bodyRegular;
  font-size: 12px;
  line-height: 140%;
  color: $fbRed__standard !important;
}

.SummaryWarning {
  margin-top: 0;
  margin-bottom: 20px;
}

.SpecialsLinkBtn {
  border: none;
  outline: none;
  background: none;
  font: $font__bodyRegular;
  font-size: 14px;
  color: $fbGrey__standard;
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
  padding: 0;
  margin: 0;

  &:hover {
    color: $fbGrey__dark;
  }

  margin-top: 8px;
  margin-bottom: 8px;
}

.ErrorMessage {
  color: $fbRed__standard;
}

.ErrorNotification {
  width: auto;
}

.InitialLoadingErrorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Label {
  font: $font__bodyRegular;
  color: $fbGrey__dark;
}

.CheckBoxLabel {
  font: $font__bodyRegular;
  color: $fbGrey__dark;
  margin-left: -14px;
}

.ProductFormRow {
  display: flex;
  flex-wrap: wrap;

  @media (min-width: $lg) {
    flex-wrap: nowrap;
  }
}

.ProductFormRow__categories {
  @media (min-width: $lg) {
    flex-wrap: wrap;
  }
}

.ProductFormRow__portions {
  display: flex;
  flex-wrap: wrap;
}

.InputContainer__name {
  width: 100%;

  @media (min-width: $lg) {
    margin-left: 12px;
    width: auto;
    flex: 1;
  }
}

.InputContainer__sku {
  width: 100%;

  @media (min-width: $lg) {
    margin-right: 12px;
    width: 33%;
  }
}

.InputContainer__price {
  width: 100%;

  @media (min-width: $lg) {
    width: auto;
    flex: 1;
    margin-right: 12px;
  }
}

.InputContainer__unit {
  width: 100%;

  @media (min-width: $lg) {
    width: auto;
    flex: 1;
    margin-left: 12px;
  }
}

.InputContainer__GSTFlag {
  width: 100%;

  @media (min-width: $lg) {
    width: auto;
  }
}

.InputContainer__category {
  width: 100%;

  @media (min-width: $lg) {
    width: 80%;
  }
}

.InputContainer__portionSection {
  width: 100%;
}

.InputContainer__portion {
  width: 100%;
  display: flex;

  @media (min-width: $lg) {
    width: 65%;
    min-width: 170px;
  }
}

.PortionInput__portionUnit {
  margin-left: 12px;
  margin-right: 24px;
  margin-top: 30px;
}

.AddPortionBtn {
  margin: 0;
}

.EnableProductContainer {
  display: flex;
  align-items: center;

  .Icon {
    color: $fbGrey__light;
    width: 18px;
    height: 18px;
    margin-left: -8px;
  }
}

.DeletePortionBtn {
  min-width: unset;
  margin-top: 18px;
  padding: 14px 10px;

  .Icon {
    color: $fbRed__standard;
  }

  &:hover {
    .Icon {
      color: white;
    }
  }
}

.InputContainer__portionUnit {
  width: 80%;
}

.InputContainer__image {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
}

.ProductImageBtn {
  margin: 0;
}

.SelectedImageName {
  margin-top: 16px;
  margin-left: 24px;
  margin-right: 12px;
}

.SubmitButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ProductSummary {
  @media (min-width: $md) {
    position: sticky;
    top: 124px;
  }
}

.ProductSummary__section {
  padding-bottom: 20px;
  font-size: 18px;

  .SummaryHeading {
    margin-bottom: 4px;
    font-size: 16px;
  }
}

.ProductSummary__gstIcon {
  display: inline;
  padding: 4px 8px;
  cursor: pointer;
}

.PortionsTable {
  width: 100%;

  td.Portion {
    font-size: 16px;
    color: $fbGrey__standard;
    padding-bottom: 8px;

    span.PortionTitle {
      font-size: 18px;
      color: $fbGrey__dark;
    }
  }

  td.Price {
    text-align: right;
    font-size: 16px;
    color: $fbGrey__standard;
    padding-bottom: 8px;
  }
}

.SubmitBtn {
  margin: auto;
}

.ProductImg {
  max-width: 50px;
  max-height: 100%;
}

.ProductImageField {
  display: flex;
  display: -webkit-inline-box;
  flex-wrap: wrap;
}

.EditBtn__addIcon {
  transition: all $navigationTransitionTime;
  color: $fbGrey__standard;
  height: 0;
  width: 0;
}
