@import '../../stylesheets/variables.scss';

.DashboardContainer {
  overflow: hidden;

  @media (min-width: $md) {
    overflow: unset;
  }
}

.PageContainer {
  max-width: $maxPageWidth;
  margin: auto;
}

.TableContainer {
  display: flex;
  justify-content: center;
}

.OrdersTable {
  td,
  th {
    width: 100px;
    padding-right: 4px;
    padding-left: 8px;

    &.OrderID {
      width: 140px;
    }

    &.Placed {
      width: 160px;
    }

    &.Delivery {
      width: 150px;
    }

    &.CompanyName {
      width: 220px;
    }

    &.CustomerName {
      width: 180px;
    }

    &.CustomerAlert {
      width: 212px;
    }

    &.Status {
      width: 120px;
    }

    &.OrderValue {
      width: 112px;
    }
  }
}

.VenueColumnChild {
  display: flex;
  overflow: hidden;
}

.VenueName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TableBtn {
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
}

.RowAction {
  margin: 8px;
}

.OrdersContainer {
  padding-top: 16px;

  @media (min-width: $md) {
    padding-top: 40px;
  }
}

.SectionHeading {
  padding-bottom: 16px;
  display: flex;
  align-items: center;
}

.HeadingBtn {
  margin: 0 0 0 16px;
}

.HeadingBtn__icon {
  color: $fbGrey__light;
  width: 16px;
  height: 16px;
}

.TypographySection {
  padding-top: 12px;
}

.TypographyBody {
  margin-bottom: 8px;
}

.SpecialsCTABtn {
  margin: 12px 0 0 0;
}

.NoResultsContainer {
  width: 100%;
  padding: 12px;

  @media (min-width: $sm) {
    padding: 20px;
  }
}

.SpecialsContainer {
  padding-top: 16px;

  @media (min-width: $md) {
    padding-top: 40px;
  }
}

.SpecialsTable {
  td,
  th {
    width: 150px;
    padding-right: 4px;
    padding-left: 8px;

    &.SKU {
      width: 76px;
    }

    &.ProductName {
      width: 220px;
    }

    &.SupplierName {
      width: 220px;
    }

    &.Date {
      width: 110px;
    }

    &.Price {
      width: 115px;
    }
  }
}

.ErrorWrapper {
  margin: auto;
  max-width: 94%;
  width: 400px;
}

.DownloadCSV__Btn {
  .icon {
    margin-right: 8px;
    color: $fbGrey__light;
    line-height: 20px;
  }

  white-space: nowrap;
}

.downloadIcon {
  color: $fbGrey__light;
  padding-right: 8px;
}

.BoldText {
  font: $font__bodyBold;
}
