@import '../../../stylesheets//variables.scss';

.Dialog__root {
  z-index: 1500 !important;
  cursor: progress;
}

.Dialog__paper {
  cursor: progress;
  background: white;
  margin: 20px !important;
  min-width: 90vw;
  border: 2px solid $fbGrey__lightest;
  border-radius: $borderRadius__modal;
  text-align: center;
  @media (min-width: $sm) {
    min-width: 500px;
  }
  @media (min-width: $md) {
    margin: inherit;
  }
}

.Dialog__title {
  padding: 24px 24px 8px 24px;
  @media (min-width: $md) {
    padding: 32px 32px 8px 32px;
  }
}

.Dialog__content {
  padding: 8px 24px 24px 24px;
  @media (min-width: $md) {
    padding: 8px 32px 32px 32px;
  }
}

.BodyText {
  margin-top: 12px;
  margin-bottom: 12px;
}

.ProgressContainer {
  padding-top: 20px;
  padding-bottom: 20px;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

.ProgressBar {
  height: 8px;
  border-radius: 4px;
  background-color: $fbGrey__light;
  div {
    box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    background: $fbGreen__light;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.2) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.2) 75%,
        transparent 75%,
        transparent
      );
      z-index: 1;
      background-size: 50px 50px;
      animation: move 2s linear infinite;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      overflow: hidden;
    }
  }
}
