@import '../../stylesheets/variables';

.PageContainer {
  padding-bottom: 80px;
}

.BreadcrumbsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 12px;
}

.ActionsContainer {
  overflow-x: scroll;
  white-space: nowrap;
  display: flex;
  margin-left: -12px;
  margin-right: -12px;
  margin-top: -70px;

  @media (min-width: $md) {
    overflow-x: unset;
    white-space: unset;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
}

.ActionBar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ActionBar__buttonsContainer {
  display: flex;
  flex-wrap: wrap;
}

.ActionBar__button {
  height: 41px;
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  margin: 8px 8px 8px 0;
}

.DropdownIcon {
  color: $fbGrey__light;
  margin-left: 4px;
}

.MenuLink {
  font: $font__bodyRegular;
  font-size: 18px;
  color: $fbGrey__standard;
  border-radius: $borderRadius__button;

  &:focus {
    color: $fbGrey__standard;
    text-decoration: none;
    outline: none;
  }

  &:hover,
  &:active {
    text-decoration: none;
  }
}

.ErrorWrapper {
  margin: auto;
  max-width: 94%;
  width: 400px;
}

.Customers_TableContainer {
  padding: 20px;
}

.CustomersTable {
  td,
  th {
    width: 120px;
    padding-right: 4px;
    padding-left: 8px;

    &.VenueId {
      width: 50px;
    }

    &.CRN {
      width: 75px;
    }

    &.VenueName {
      width: 150px;
    }

    &.VenueGroup {
      width: 150px;
    }

    &.VenueOwner {
      width: 220px;
    }

    &.Postcode {
      width: 105px;
    }

    &.Commission {
      width: 75px;
    }

    &.CustomPrice {
      width: 150px;
    }

    &.Orders {
      width: 90px;
    }

    &.LastOrdered {
      width: 180px;
    }
  }

  td {
    &.Price {
      margin-left: -16px;
      padding-right: 16px;
    }
  }

  th {
    &.Status {
      :first-child {
        justify-content: center;
      }
    }
  }
}

.NoResultsContainer {
  text-align: center;
  margin: auto;
  margin: 16px;
  padding: 12px;

  @media (min-width: $sm) {
    margin: 22px 12px 22px 12px;
    padding: 25px;
  }
}

.NoResultsActions {
  display: flex;
  justify-content: center;
}

.TypographySection {
  padding-top: 12px;
  padding-bottom: 12px;
}

.TypographyBody {
  margin-bottom: 8px;
}

.ViewProductBtn {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SearchBarContainer {
  padding-top: 8px;
  width: 450px;
}

.SearchBar {
  @media (min-width: $sm) {
    width: 220px;
  }
}

.LoadRecentResultsButton {
  margin: auto;
}
