@import '../../../stylesheets/variables.scss';

.CancelOrderDialogRoot {
  z-index: 1500 !important;
}

.CancelOrderDialogPaper {
  margin: 20px !important;
  min-width: 90vw;
  max-width: 90vw;
  border: 2px solid $fbGrey__lightest;
  border-radius: $borderRadius__modal;
  min-height: 350px;

  @media (min-width: $md) {
    min-width: 450px;
    max-width: 450px;
  }
}

.CancelOrderDialog__header {
  background: white;
  border-radius: $borderRadius__modal $borderRadius__modal 0 0;
  color: $fbGrey__dark;
}

.CancelOrderDialog__closeBtn {
  position: absolute;
  right: 0;
  top: 0;
}

.CancelOrderForm {
  margin-top: 40px;
}

.CancelOrderForm__actions {
  margin-top: 40px;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
}

.InputRow {
  flex-wrap: wrap;
  width: 100%;
  display: flex;

  @media (min-width: $md) {
    flex-wrap: nowrap;
  }
}

.FullWidthInputContainer {
  width: 100%;
}

.ErrorContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.SubmitLoadingSpinner {
  line-height: 24px;
  width: 22px !important;
  height: 22px !important;
  color: $fbGrey__light;
  margin-right: 12px;

  svg {
    margin: 0;
  }
}

.CancelDisclaimer {
  color: $fbGrey__standard;
}
