.minesweeper {
  user-select: none;
  border-collapse: collapse;
  border-spacing: 0;
  padding: 10px;
  display: flex;
  justify-content: center;
  font-size: 24px;
}

.minesweeper__body {
  border: 2px solid #7b7b7b;
}

.minesweeper__row {
  display: flex;
}

.minesweeper__field {
  background: #dedede;
  position: relative;
  border: 1px solid #7b7a7a;
  width: 30px;
  height: 30px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.minesweeper__field_1 {
  color: #1212f9;
}

.minesweeper__field_2 {
  color: #007b00;
}

.minesweeper__field_3 {
  color: #ef2e2e;
}

.minesweeper__field_4 {
  color: #00007b;
}

.minesweeper__field_5 {
  color: #7b0000;
}

.minesweeper__field_6 {
  color: #007f7e;
}

.minesweeper__field_7 {
  color: #000;
}

.minesweeper__field_8 {
  color: #000;
}

.minesweeper__field_bomb::before {
  background: url(../../images/maintenance/bomb.svg);
}

.minesweeper__field_cloud::before {
  background: #bdbdbd;
  border-left: 2px solid #fefefe;
  border-top: 2px solid #fefefe;
  border-bottom: 2px solid #7b7b7b;
  border-right: 2px solid #7b7b7b;
  box-sizing: border-box;
}
.minesweeper__field_cloud:active::before {
  transform: translate(1px, 1px);
}

.minesweeper__field_flag::after {
  background: inherit;
}

.minesweeper__field_flag::before {
  z-index: 5;
  background: url(../../images/maintenance/flame.svg);
}

/* stylelint-disable */
.minesweeper__field_bomb::before,
.minesweeper__field_flag::before,
.minesweeper__field_flag::after,
.minesweeper__field_cloud::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 75% 75%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
