@import '../../../stylesheets/variables.scss';

.RowCard {
  display: flex;
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 12px;
}

.RowCardNumber {
  margin-right: 24px;
  padding-top: 12px;
  padding-left: 24px;
  color: $fbGrey__standard;
}

.RowCardField {
  margin-right: 24px;
  width: 30%;
  padding-top: 12px;
  text-align: center;
  color: $fbGrey__standard;

  &.firstField {
    text-align: start;
  }

  &.lastField {
    text-align: end;
  }

  &.widerFirstField {
    text-align: start;
    width: 60%;
  }
}
